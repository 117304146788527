import React, {
    useState,
    useEffect
} from "react";
import {
    Link,
    useNavigate
} from "react-router-dom";
import { Rating } from 'react-simple-star-rating'

import Header from '../includes/Header';
import Sidebar from "../includes/Sidebar";
import {
    userIcon,
    streamingIcon,
    angleLeft
} from '../ImagesIcons.js';
import {
    entryStreamfees,
   checkUsertypeRedirectIfTeacher,
    showLoader,
    SiteLogo,
    SiteLogoDark,
    AkuserId,
    Url,
    SetNotificationAfterName,
    RemoveNotificationAfterName,
    Notifyalert,
    UrlSplitter
} from '../Frontapi.js';
const Livestreams = () => {

 


        const [BottomNavbarstatus, setBottomNavbarstatus] = useState(1)
        const username = UrlSplitter(2);
        const [Userprofilepicture] = useState({
            'preview': SiteLogoDark
        });
        const [streamsList, setstreamsList] = useState([]);
        const [currentPage, setCurrentPage] = useState(1);
        const [recordsPerPage, setrecordsPerPage] = useState(10);

          const [rating, setRating] = useState(0)
          const [defaultRating, setdefaultRating] = useState(0)



          var ratings =new Array();


          const [showratings, setShowratings] = useState({})
 

        

          
         //   var [ratings, setRatings] = useState({})



        const [activeLivestream, setactiveLivestream] = useState(1);

        
                const [activeMystream, setactiveMystream] = useState(0);


        const [activeRatestream, setactiveRatestream] = useState(0);
        const [checkrecordsLength, setcheckrecordsLength] = useState('Loading...')



        const [streamentryfees, setstreamentryfees] = useState(entryStreamfees);

        

          const [showStreamsection, setStreamsection] = useState(1);

                const [showAgreementsection, setshowAgreementsection] = useState('0');
                 const [showsubmitButton, setshowsubmitButton] = useState(1);
                   const toggleBackAddsection = async (e) => {
            setshowAgreementsection(0)
            setStreamsection(1)

         }


         const [paystreamId, setpaystreamId] = useState(0);
         const Attendstream = async (streamid) => {

              setshowAgreementsection(1)
              setStreamsection(0)
                
 
            setpaystreamId(streamid)

         }



       

           const Paystream = async (e) => {

setshowsubmitButton(0)
              var postdata = {
                userid: AkuserId,
                streamid: paystreamId,
            
            }
            const response = await fetch(Url + "pay-streamsession", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        postdata
                    })
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        window.location.href = data.redirect
                       
                    } else {
                        if (typeof data.message != 'undefined') {
                            Notifyalert('error', data.message)
                        }
                    }
                })






           }


         
        



        const showFollowers = () => {}
        const showFollowings = () => {}




         const checkStream = async (value) => {
            var postdata = {
                'currentpage': 1,
                'perpage': recordsPerPage,
                'checkuserid': AkuserId
            };
            const response = await fetch(Url + "check-stream", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        GetLiveStreamsessions();
                    } else {
                        GetLiveStreamsessions();
                    }
                })
        };


         const GetLiveStreamsessions = async (value) => {
            setcheckrecordsLength('Loading...')
            
             setactiveRatestream(0)
            setactiveMystream(0)
            setactiveLivestream(1)

            showLoader(true);
            setstreamsList([])
            // let formData = new FormData()
            //  formData.append('currentpage', currentPage)
            //   formData.append('perpage', recordsPerPage)
            value = (typeof value !== 'undefined') ? value : recordsPerPage;
            var postdata = {
                'currentpage': 1,
                'perpage': recordsPerPage,
                'checkuserid': AkuserId
            };
            const response = await fetch(Url + "getstreamsessions", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        var postdatavalues = data.data;
                        if (parseInt(data.count) == parseInt(0)) {
                            setcheckrecordsLength('No Streams found')
                        }
                        setstreamsList(postdatavalues);
                    } else {}
                })
        };


        const GetMyStreamsessions = async (value) => {
            setcheckrecordsLength('Loading...')
             setactiveRatestream(0)
            setactiveLivestream(0)
            setactiveMystream(1)
            showLoader(true);
            setstreamsList([])
            value = (typeof value !== 'undefined') ? value : recordsPerPage;
            var postdata = {
                'my': 1,
                'currentpage': 1,
                'perpage': recordsPerPage,
                'checkuserid': AkuserId
            };
            const response = await fetch(Url + "getstreamsessions", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        var postdatavalues = data.data;
                        if (parseInt(data.count) == parseInt(0)) {
                            setcheckrecordsLength('No Streams found')
                        }
                        setstreamsList(postdatavalues);
                    } else {}
                })
        }
       




       




          const GetMyFinishedStreamsessions = async (value) => {

                    setactiveLivestream(0)
                    setactiveMystream(0)
                    setactiveRatestream(1)



                     value = (typeof value !== 'undefined') ? value : recordsPerPage;
            var postdata = {
                'finished': 1,
                'currentpage': 1,
                'perpage': recordsPerPage,
                'checkuserid': AkuserId
            };
            const response = await fetch(Url + "getstreamsessions", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        var postdatavalues = data.data;
                        if (parseInt(data.count) == parseInt(0)) {
                            setcheckrecordsLength('No Streams found')
                        }



                        //update for ratings initiated
                         var showratings = [];
                           Object.keys(postdatavalues).forEach(function(key) {
                                

                                showratings[postdatavalues[key]._id]=1


                             });
                            var showratingsobj = Object.assign({}, showratings);
                          
                
                         
                         setShowratings(showratingsobj)
                         //update for ratings initiated

                           setstreamsList(postdatavalues);



                       
                     



                    } else {}
                })




          }


         const rateStream = async (value) => {

         ratings = ratings[value];
        let objec = Object.assign({}, ratings);


//var size = Object.keys(objec).length;


        //update for ratings submitted
        var showratingsupdate = [];
        Object.keys(showratings).forEach(function(key,keyvalue) {

        showratingsupdate[key]=keyvalue;
        if(key == value){
          showratingsupdate[key]=0;
        }

        });
        var showratingsobj = Object.assign({}, showratingsupdate);
        setShowratings(showratingsobj)
        //update for ratings submitted


         
 
  var postdata = {
                'streamid':value,
                'ratings': objec,
                'checkuserid': AkuserId
            };

            const response = await fetch(Url + "ratestreamsessions", {
                 method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        
                        Notifyalert('success', data.message)


                    } else {}
                })





         }



          





        const checkUsertypeRedirect = async (value) => {


         await checkUsertypeRedirectIfTeacher()

           
        };




const setFeedback= (e,stream_id) => {


  ratings = ratings;
        if (typeof ratings[stream_id] !== 'undefined') {


        }else{
            ratings[stream_id]=[]
        }
         ratings[stream_id]['feedback'] =e.target.value


}
  const handleRating = (rate,stream_id,type) => {
     

 
        ratings = ratings;


 
        if (typeof ratings[stream_id] !== 'undefined') {


        }else{
            ratings[stream_id]=[]
        }
         ratings[stream_id][type] =rate

 
 


    //setRating(rate)

    // other logic
  }





        useEffect(() => {

            checkUsertypeRedirect();

            checkStream();
        }, [recordsPerPage]);
 
  return (
<div>

   <Header pageInfo="Streams" />


   <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
      <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
         <Sidebar showBottomNavbar={BottomNavbarstatus} />
      </div>
      <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 col-12 full-section">
         <div className="page-content">
            <div className="container-mobile">


            {(showStreamsection == 1)?  
            <div>
               <div className="page-container-section"  >
                  <h1 className="page-container-title cur-poi"   > 
                    <span className="goto-section  mob-view-back display_none" > <Link  to={-1}>  <img src={angleLeft} /> </Link>   </span> Streaming
                     <span className="live-streaming-title" style={{float:'right'}}>  <img className="streaming-icon" src={streamingIcon} /> </span>
                  </h1>
               </div>
               <div className="live-info-tabs" >
                  <ul>
                     <li onClick={GetLiveStreamsessions}  className="info-text" > <a className={activeLivestream == 1 ? 'active':''}>  Live</a> </li>
                     <li > <a className="pipeline"> &nbsp; </a> </li>
                     <li onClick={GetMyStreamsessions} className="info-text"> <a className={activeMystream == 1 ? 'active':''}> My Sessions </a></li>

                      <li > <a className="pipeline"> &nbsp; </a> </li>

                      <li onClick={GetMyFinishedStreamsessions} >  <a className={activeRatestream == 1 ? 'active':''}>  Rate Session </a></li>



 
                  </ul>
               </div>  </div>   : ''}




{/*Livesession*/}
               {(showStreamsection == 1) ?

 <div className="live-session"> 

               {    (streamsList.length) > 0 ?

               <div className="stream-posts">




               {(activeLivestream) == 1 ?
                    <div className="stream-info-highlight">
                    <p className="text-center highlight-clr">Joining below will cost you Rs. {streamentryfees}/- per session </p>

                    <p className="text-center highlight-clr"><small>Attention! We offer sessions in multiple languages. Please select your preferred language to participate</small> </p>
                    </div> : ''}


                     {(activeMystream) == 1 ?   <div className="stream-info-highlight">
                    <p className="text-center highlight-clr">Below are the purchased session </p>

                    <p className="text-center highlight-clr"><small>I will not share these session link with any person, if share
FSTSTEPS Pvt. Ltd. have right to take legal action against me</small> </p>
                    </div> : ''}

                    {(activeRatestream) == 1 ?   <div className="stream-info-highlight">
                    <p className="text-center highlight-clr">Rate the sessions you have attended</p>

                    <p className="text-center highlight-clr"><small>Your rating are important to us, if you didn’t like tell us
we will make sure those teachers blocked</small> </p>
                    </div> : ''}


                    




                    


                {streamsList.map((stream) => (
<div className="stream-post-single">
   <div className="stream-post-user-info col-12"  >
      <span className="stream-post-user-name col-6">
         <div className="stream-post-user-img-sec"> <img src={stream.userprofilepicture} />   <span>{stream.username}</span> </div>
      </span>


       <span className="stream-post-add-info col-6 text-right">
         <div className="stream-post-user-img-sec highlight-clr "> Language : {stream.sessionlanguage}   </div>
      </span>


      


    
    </div>
   <div className="stream-post-info col-12"  >
      <span className="stream-post-user-name col-6"> Subject :  {stream.subjectname}  </span>

        <span className="stream-post-add-info col-6 text-right">
         <div className="stream-post-user-img-sec"> Std : {stream.standard}   </div>
      </span>
   
   </div>

 <div className="stream-post-info col-12"  >

   <span className="stream-post-add-info col-6  "> Topic :  {stream.topic}  </span>

     <span className="stream-post-add-info col-6 text-right">

 <span className="stream-info-datetime"> Starts at : <small> {stream.startdatetime}  </small> </span>
</span>
    </div>


   <div className="stream-post-info col-12"  >
      {stream.caption}  
   </div>



   {(stream.showlink == 1) ? 

   <div className="stream-post-info col-12"  >



     <a href={stream.meetinglink} target="_blank"  className="submit-stream-btn bg-yellow-amigo"> Join   </a>
   </div> : ''}


   {(stream.showpaybutton == 1) ? 

   <div className="stream-post-info col-12"  >
       <a onClick={(streamid)=>  Attendstream(stream._id)} className="btn bg-yellow-amigo text-white">Show Meeting Link</a>
   </div> : ''}


    {(stream.showclosed == 1) ? 

   <div className="stream-post-info col-12"  >
      Status : Closed
   </div> : ''}


   {(stream.showrating == 1  &&  showratings[stream._id] == 1  ) ? 

<div className="rate-stream-section">
 

    <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
      <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12  ">
         <h4 className="highlight-clr text-left rate-stream-section-heading">How you rate the session? </h4>
      </div>
   </div>
   <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
      <div className="col-9 col-md-9 col-lg-9 col-sm-9 col-xs-9 col-xl-9">
         <p className="highlight-clr text-left"> Did you enjoyed the session ? </p>
      </div>
      <div className="col-3 col-md-3 col-lg-3 col-sm-3 col-xs-3 col-xl-3"> 

      <Rating
        onClick={ (e,stream_id,ratetype) =>handleRating(e,stream._id,'favourenjoyed')} initialValue={defaultRating}
     
      />

       </div>
   </div>


   <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
      <div className="col-9 col-md-9 col-lg-9 col-sm-9 col-xs-9 col-xl-9">
         <p className="highlight-clr text-left"> All questions were answered ? </p>
      </div>
      <div className="col-3 col-md-3 col-lg-3 col-sm-3 col-xs-3 col-xl-3"> 

      <Rating
        onClick={ (e,stream_id,ratetype) =>handleRating(e,stream._id,'questionsresponse')} initialValue={defaultRating}
     
      />
       </div>
   </div>


   <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
      <div className="col-9 col-md-9 col-lg-9 col-sm-9 col-xs-9 col-xl-9">
         <p className="highlight-clr text-left"> How was the communication skill? </p>
      </div>
      <div className="col-3 col-md-3 col-lg-3 col-sm-3 col-xs-3 col-xl-3"> 
      <Rating
        onClick={ (e,stream_id,ratetype) =>handleRating(e,stream._id,'communicationskills')} initialValue={defaultRating}
     
      />
       </div>
   </div>


   <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
      <div className="col-9 col-md-9 col-lg-9 col-sm-9 col-xs-9 col-xl-9">
         <p className="highlight-clr text-left"> All topics were covered? </p>
      </div>
      <div className="col-3 col-md-3 col-lg-3 col-sm-3 col-xs-3 col-xl-3"> 
      <Rating
        onClick={ (e,stream_id,ratetype) =>handleRating(e,stream._id,'topicscovered')} initialValue={defaultRating}
     
      />

       </div>
   </div>


   <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
      <div className="col-9 col-md-9 col-lg-9 col-sm-9 col-xs-9 col-xl-9">
         <p className="highlight-clr text-left"> Would you recommend this teacher? </p>
      </div>
      <div className="col-3 col-md-3 col-lg-3 col-sm-3 col-xs-3 col-xl-3"> 
      <Rating
        onClick={ (e,stream_id,ratetype) =>handleRating(e,stream._id,'recommendation')} initialValue={defaultRating}
     
      />

       </div>
   </div>


   <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
        <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12">
         <p className="highlight-clr text-left"> Anything we should know? </p>
         <textarea className="width-100-percent"  onChange={  (e,stream_id) =>setFeedback(e,stream._id)} rows="5"></textarea>
      </div>

</div>


 <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
        <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12">
   <button onClick={ (stream_id) =>rateStream(stream._id)}  className=  "me-1 mb-1 submit-stream-btn mt-25 bg-yellow-amigo"    >Submit</button>

         </div>

</div>


 </div> : ''}


</div>
                 ))}

               </div> :  <div className="no-records"> {checkrecordsLength} </div>  
           }

</div>: ''}




 



{   (showAgreementsection == 1)  ? 


               <div className="agreement-section"> 



               <div className="page-container-section"  >
 
                  <h1 className="page-container-title cur-poi"   >
                     <span className="goto-section" onClick={toggleBackAddsection} >
                       
                        <img src={angleLeft} /> 
                     </span>
                      Agreement
                  </h1>
               </div>


               <div className="stream-agreement"> 

<p><span className="stream-highlight">I here to learn and clear my doubts and learn my 
subject. I will follow all rules and regulations of </span> <b className="stream-author">Fststeps Pvt. Ltd.</b></p>

<h5>Rules and Regulations</h5>
<ol>
<li>I will maintain good behaviour during session </li>
<li> No comments on Religion, Caste or Nationality</li>
<li>No abusive language </li>
<li> I not share any paid link to my friends or relatives</li>
<li> Sessions will be between 30 min to 45 min.</li>
<li>Fstetps Pvt. Ltd., is only providing platform and they
are not responsible for my actions.</li>
</ol>

<h5>Payments</h5>
<ol>

<li> Payments are made for one session and it is not 
refundable  </li>
<li> I understand Fststeps Pvt. Ltd., is the aggregator and
teachers are not hired by Fststeps Pvt. Ltd.  </li>
 
</ol>


<h5>Important Point to be considered</h5>
<ol>

<li>  I will make sure my network signals are strong and
no disconnection caused from my side</li>
<li> Any disconnection caused due to network is fully my
Responsibility</li>
<li>I respect time of teacher and other students</li>
</ol>

               </div>


 {(showsubmitButton == 1) ? 

             <button onClick={Paystream}  className=  "me-1 mb-1 submit-stream-btn agree-proc-btn bg-yellow-amigo"    >I agree</button>
: 
             <button    className={ "me-1 mb-1 submit-stream-btn agree-proc-btn bg-yellow-amigo  disabled-btn" }  >I agree</button>


}


               </div> : ''}



{/*Livesession*/}

{/*Purchasedsession*/}




{/*Purchasedsession*/}



{/*Finishedsession*/}




{/*Finishedsession*/}





            </div>
         </div>
      </div>
   </div>
</div>
  );
};
export default Livestreams;