import React, {
    useState,
    useEffect
} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    Link,
    useNavigate
} from "react-router-dom";
import Select from 'react-select';


import {
    GetSchoolsCommon,
    GetSubjectsCommon,
    UrlSplitter,
    Socketiourl,
    Akusername,
    editPosturl,
    frontUrl,
    GetProfilePicture,
    Notifyalert,
    SetNotificationAfterName,
    RemoveNotificationAfterName,
    SiteLogo,
    SiteLogoDark,
    AkuserId,
    Akusertype,
    Url,
    userProfileurl,
    GetEmbedVideoUrl
} from '../Frontapi.js';
import {
    angleLeft
} from '../ImagesIcons.js';
 import Header from '../includes/Header';

import Sidebar from "../includes/Sidebar";
import Share from "../share/Share"
import Video from "../video/Video"
import Singlepost from "../pages/Singlepost"
 
import {
    Socketcall
} from '../../socketcall/Socketcall.js';
const Profile = () => {
        const navigate = useNavigate()
        const [BottomNavbarstatus, setBottomNavbarstatus] = useState(1)
        //const [Userprofilepicture, setuserprofilepicture] = useState({ preview: SiteLogoDark, data: '' });
        const [UserProfilepicture, setUserProfilepicture] = useState({
            preview: SiteLogoDark,
            data: ''
        });
          const [UserProfilepictureedit, setUserProfilepictureedit] = useState({
            preview: SiteLogoDark,
            data: ''
        });
        const [myProfile, setshowmyProfile] = useState(1);
        const [userOptions, setshowuserOptions] = useState(0);
        const [mypostsList, setmypostsList] = useState([]);
        const [editProfile, setshoweditProfile] = useState(0);
        const [Showsharepopup, setShowsharepopup] = useState(0);
        const [showUpdateProfileButton, setshowUpdateProfileButton] = useState(0);
        const [editProfilepic, setshoweditProfilepic] = useState(0);
        const [Followerslistview, setshowfollowers] = useState(0);
        const [Followingslistview, setshowfollowings] = useState(0);
        const [Savedlistview, setshowsavedlist] = useState(0);
        const [Contactview, setshowcontact] = useState(0);
        const [Notificationsview, setshownotifications] = useState(0);
        const [Changepasswordview, setshowchangepassword] = useState(0);
        const [Followerslist, setfollowerslist] = useState([]);
        const [Followingslist, setfollowingslist] = useState([]);
        const [Savedlist, setsavedlist] = useState(0);
        const [savedpostsList, setsavedpostsList] = useState([]);
        const [studentsavedpostsList, setstudentsavedpostsList] = useState([]);
        const [teachersavedpostsList, setteachersavedpostsList] = useState([]);
        const [userdata, setuserdata] = useState({
            name: ''
        });
        const [schoolsList, setschoolsList] = useState([]);
        const [subjectsList, setsubjectsList] = useState([]);
        const [username, setUsername] = useState("");
        const [name, setName] = useState("");
        const [lastname, setLastname] = useState("");
        const [mobile, setMobile] = useState("");
        const [mobiledit, setMobileedit] = useState("");
        
        const [school, setSchool] = useState("");
        const [subject, setSubject] = useState("");
        const [standard, setStandard] = useState("");
        const [university, setUniversity] = useState("");
        const [pincode, setPincode] = useState("");
        const [userdataname, setuserdataname] = useState("");
        const [contactemail, setcontactEmail] = useState('');
        const [contactmessage, setcontactMessage] = useState('');
        const [notifylikes, setNotifylikes] = useState(0);
        const [notifycomments, setNotifycomments] = useState(0);
        const [notifyfollows, setNotifyfollows] = useState(0);
        const [oldpassword, setoldpassword] = useState('');
        const [newpassword, setnewpassword] = useState('');
        const [confirmnewpassword, setconfirmnewpassword] = useState('');
        const [shareurl, setShareurl] = useState('')
        const [followingscount, setfollowingscount] = useState(0);
        const [followerscount, setfollowerscount] = useState(0);
        const [followtext, setfollowtext] = useState('Follow')
        const [postscount, setpostscount] = useState(0);
        const [savedlisttype, setsavedlisttype] = useState(2);
        const [Studentactivesavedlist, setStudentactivesavedlist] = useState(1)
        const [Teacheractivesavedlist, setTeacheractivesavedlist] = useState(0)
        var screenheight = parseInt(window.screen.height) - parseInt(175)
        const [checkprecordsLength, setcheckprecordsLength] = useState('Loading...')
        const [checkslrecordsLength, setcheckslrecordsLength] = useState('Loading...')
        const tabtype = UrlSplitter(2);




/***Schools only***/
        const [Schooloptions, setschoolsOptions] = useState();
        const [selectedSchoolOption, setselectedSchoolOption] = useState({ value: '', label: 'Select School*' } )
       const  emptyselectedSchoolOption =() => {
          setselectedSchoolOption({ value: ' ', label: ' ' })
        }
        const handleChange = (value) => {
        //document.getElementsByClassName('select-school-input')[0].classList.remove('notify-input-missing');
        setSchool(value.value);

        setselectedSchoolOption(value)

        setshowUpdateProfileButton(1)

        }
   /***Schools only***/

          /**Subject***/

        const [Subjectoptions, setsubjectsOptions] = useState();
        const [selectedSubjectOption, setselectedSubjectOption] = useState({ value: '', label: 'Select Subject*' }  )

           const  emptyselectedSubjectOption =() => {
          setselectedSubjectOption({ value: ' ', label: ' ' })
        }
        const handleChangeSubject = (value) => {
        //document.getElementsByClassName('select-subject-input')[0].classList.remove('notify-input-missing');
        setSubject(value.value);
        setselectedSubjectOption(value)
                setshowUpdateProfileButton(1)

        }

 

        /**Subject***/

        /**Standard***/

        const [Standardoptions, setstandardsOptions] = useState([
 
{ value: '5', label:   '5th'  },
{ value: '6', label:   '6th'  },
{ value: '7', label:   '7th'  },
{ value: '8', label:   '8th'  },
{ value: '9', label:   '9th'  },
{ value: '10', label:   '10th'  },
{ value: '11', label:   '11th'  },
{ value: '12', label:   '12th'  }]);
        const [selectedStandardOption, setselectedStandardOption] = useState({ value: '', label: 'Select Standard*' }  )

           const  emptyselectedStandardOption =() => {
          setselectedStandardOption({ value: ' ', label: ' ' })
        }
        const handleChangeStandard = (value) => {
        //document.getElementsByClassName('select-standard-input')[0].classList.remove('notify-input-missing');
        setStandard(value.value);
        setselectedStandardOption(value)
                setshowUpdateProfileButton(1)

        }


        /**Standard***/



        const Updatepassword = async (e) => {
            e.preventDefault();
            try {
                var postdata = {
                    'changepassword': 1,
                    oldpassword: oldpassword,
                    password: newpassword,
                    userid: AkuserId
                };
                /***Check Required***/
                var required_fields = document.getElementsByClassName('MustNewPasswordEnterField')
                if (document.getElementsByClassName('notify-input-text')[0]) {
                    document.getElementsByClassName('notify-input-text')[0].remove('notify-input-missing');
                }
                var error = 0;
                Array.prototype.forEach.call(required_fields, function(el) {
                    el.classList.remove('notify-input-missing');
                    el.classList.add('mb-25');
                    if (el.value === '' && error == 0) {
                        var inputname = el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                        el.classList.remove('mb-25');
                        error = 1;
                      //  SetNotificationAfterName(el.name, inputname + ' required')
                    }
                });
                /***Check Required***/
                if (error === 1) {
                    return false
                }
                if (newpassword !== confirmnewpassword) {
                    SetNotificationAfterName('newpassword', 'Password and Confirm Password Don\'t Matches')
                    return false
                }
                const response = await fetch(Url + "change-user-password", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            postdata
                        })
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            Notifyalert('success', data.message)
                            document.getElementById("PasswordForm").reset();
                        } else {
                            Notifyalert('error', data.message)
                            //  ;
                        }
                    })
            } catch (error) {
                //   
            }
        };
        const Updatenotificationsettings = async (e) => {
            e.preventDefault();
            var postdata = {
                notifysettings: 1,
                notifylikes: notifylikes,
                notifycomments: notifycomments,
                notifyfollows: notifyfollows,
                userid: AkuserId
            }
            const response = await fetch(Url + "update-user-notifications-settings", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        postdata
                    })
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        Notifyalert('success', data.message)
                    } else {
                        //  ;
                    }
                })
        }
        const SubmitContactus = async (e) => {
            e.preventDefault();
            try {
                var postdata = {
                    contactemail: contactemail,
                    contactmessage: contactmessage,
                    userid: AkuserId
                };
                /***Check Required***/
                var required_fields = document.getElementsByClassName('MustContactEnterField')
                if (document.getElementsByClassName('notify-input-text')[0]) {
                    document.getElementsByClassName('notify-input-text')[0].remove('notify-input-missing');
                }
                var error = 0;
                Array.prototype.forEach.call(required_fields, function(el) {
                    el.classList.remove('notify-input-missing');
                    el.classList.add('mb-25');
                    if (el.value === '' && error == 0) {
                        var inputname = el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                        el.classList.remove('mb-25');
                        error = 1;
                       // SetNotificationAfterName(el.name, inputname + ' required')
                    }
                });
                /***Check Required***/
                if (error === 1) {
                    return false
                }
                const response = await fetch(Url + "create-inquiry", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            postdata
                        })
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            Notifyalert('success', data.message)
                            document.getElementById("ContactForm").reset();
                            setcontactMessage('')
                        } else {
                            //  ;
                        }
                    })
            } catch (error) {}
        };
        const GetMyposts = async (e) => {
            var postdata = {
                userid: AkuserId,
                status: 'any'
            };
            const response = await fetch(Url + "get-user-posts", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.data.length == 0) {
                        setcheckprecordsLength('No posts added')
                    }
                    if (data.success && data.data !== null) {
                        // var userinfo = data.data;
                        var postdatavalues = data.data
                        Object.keys(postdatavalues).forEach(function(key) {
                            (async function() {
                                 postdatavalues[key].videolink = await GetEmbedVideoUrl( postdatavalues[key].youtubeurllink )   
                            })()
                        });
                        setmypostsList(postdatavalues)
                    }
                })
        }
        const Getprofile = async (e) => {
            var postdata = {
                userid: AkuserId
            };
            const response = await fetch(Url + "get-user-profile", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success && data.data !== null) {
                        var userinfo = data.data;
                            
                             setselectedSchoolOption({ value: userinfo.school , label: userinfo.schoolname })

                             setselectedSubjectOption({ value: userinfo.subject , label: userinfo.subjectname })

                             setselectedStandardOption({ value: userinfo.standard , label: userinfo.standardname })
                            
                            setcontactEmail(userinfo.email)
                            setUsername(userinfo.username)
                            setName(userinfo.name)
                            setLastname(userinfo.lastname)
                            setMobile(userinfo.mobile)
                            setSchool(userinfo.school)
                            setSubject(userinfo.subject)
                            setStandard(userinfo.standard)
                            setUniversity(userinfo.university)
                            setPincode(userinfo.pincode)
                            setNotifylikes(userinfo.notifylikes)
                            setNotifycomments(userinfo.notifycomments)
                            setShareurl(userProfileurl + userinfo.username)
                            setfollowerscount(userinfo.followerscount);
                            setfollowingscount(userinfo.followingscount);
                            setpostscount(userinfo.postscount);
                            setUserProfilepicture({
                                preview:  userinfo.profilepicture 
                            })
                             setUserProfilepictureedit({
                                preview:  userinfo.profilepicture 
                            })
                         
                    }
                })
        }
        const Updateprofile = async (e) => {
            e.preventDefault();
            try {
                let formData = new FormData()
                formData.append('profilepicture', UserProfilepictureedit.data)
                formData.append('userid', AkuserId)
                formData.append('name', name)
                formData.append('lastname', lastname)
                formData.append('mobile', mobile)
                formData.append('school', school)
                formData.append('standard', standard)
                formData.append('university', university)
                formData.append('pincode', pincode)
                var postdata = {
                    userid: AkuserId,
                    name: name,
                    lastname: lastname,
                    mobile: mobile,
                    school: school,
                    subject: subject,
                    standard: standard,
                    university: university,
                    pincode: pincode
                };
                /***Check Required***/
                var required_fields = document.getElementsByClassName('MustEnterField')
                if (document.getElementsByClassName('notify-input-text')[0]) {
                    document.getElementsByClassName('notify-input-text')[0].remove('notify-input-missing');
                }
                var error = 0;
                Array.prototype.forEach.call(required_fields, function(el) {
                    el.classList.remove('notify-input-missing');
                    el.classList.add('mb-25');
                    if (el.value === '' && error == 0) {
                        var inputname = el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                        el.classList.remove('mb-25');
                        error = 1;
                        SetNotificationAfterName(el.name, inputname + ' required')
                    }
                });
                /***Check Required***/
                if (error === 1) {
                    return false
                }
                const response = await fetch(Url + "update-user-profile", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            postdata
                        })
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            Notifyalert('success', data.message)
                        } else {
                            //  ;
                        }
                    })
            } catch (error) {
                //   
            }
        };
        const Updateuserprofilepicture = async () => {
            RemoveNotificationAfterName();
            if (UserProfilepictureedit.preview == '') {
                SetNotificationAfterName('profilepicture', 'Image Required (jpg, png, webp only supported  ) ')
                return false;
            }
            let formData = new FormData()
            formData.append('userprofilepicture', UserProfilepictureedit.data)
            formData.append('userid', AkuserId)
            const response = await fetch(Url + "update-user-profile-picture", {
                    method: "POST",
                    body: formData
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        Notifyalert('success', data.message)
                        window.location.href = "/my-profile"
                        //        setuserprofilepicture({preview:UserProfilepicture.preview})
                    } else {
                        //  ;
                    }
                })
        };
        const handlePictureChange = (e) => {
            RemoveNotificationAfterName();
            const img = {
                preview: URL.createObjectURL(e.target.files[0]),
                data: e.target.files[0],
            }
            var name = e.target.files[0].name;
            var lastDot = name.lastIndexOf('.');
            var fileName = name.substring(0, lastDot);
            var ext = name.substring(lastDot + 1);
            
             setUserProfilepictureedit({
                preview: ''
            })
            if (ext == 'jpg' || ext == 'png' || ext == 'webp' || ext == 'jpeg') {
            //    setUserProfilepicture(img)
                setUserProfilepictureedit(img)
            } else {
                SetNotificationAfterName('userprofilepicture', 'Invalid File')
            }
        }
        const HideAllSections = (e) => {
            setBottomNavbarstatus(0)
            setshowmyProfile(0)
            setshoweditProfile(0)
            setshoweditProfilepic(0)
            setshowfollowings(0)
            setshowfollowers(0)
            setshowsavedlist(0)
            setshowcontact(0)
            setshownotifications(0)
            setshowchangepassword(0)
        }
        const showmyProfile = (e) => {
            HideAllSections()
            setshowmyProfile(1)
            setBottomNavbarstatus(1)
        }
        const showeditProfile = (e) => {
            HideAllSections()
            setshoweditProfile(1)
        }
        const showeditProfilepic = (e) => {
            HideAllSections()
            setshoweditProfilepic(1)
        }
        const showFollowers = (e) => {
            setshowfollowings(0)
            //HideAllSections()
            setshowfollowers(1)
            GetFollowers();
            setTimeout(function() {
                if (document.getElementById('followinglistmodal')) {
                    document.getElementById('followinglistmodal').style.display = 'none'
                }
                document.getElementById('followerlistmodal').style.display = 'block'
            }, 200)
        }
        const showFollowings = (e) => {
            setshowfollowers(0)
            // HideAllSections()
            setshowfollowings(1)
            GetFollowings();
            setTimeout(function() {
                if (document.getElementById('followerlistmodal')) {
                    document.getElementById('followerlistmodal').style.display = 'none'
                }
                document.getElementById('followinglistmodal').style.display = 'block'
            }, 200)
        }
        const stoppropagation = async (e) => {
            e.stopPropagation();
        }
        const showSaved = (e) => {
            HideAllSections()
            setshowsavedlist(1)
            GetSavedlists(2)
        }
        const ShowStudentsavedlist = (e) => {
            setStudentactivesavedlist(1)
            setTeacheractivesavedlist(0)
            GetSavedlists(2)
        }
        const ShowTeachersavedlist = (e) => {
            setsavedlisttype(1)
            GetSavedlists(1)
            setStudentactivesavedlist(0)
            setTeacheractivesavedlist(1)
        }
        const showChangepassword = (e) => {
            HideAllSections()
            setshowchangepassword(1)
        }
        const showContactus = (e) => {
            HideAllSections()
            setshowcontact(1)
        }
        const showNotifications = (e) => {
            HideAllSections()
            setshownotifications(1)
        }
        const showuserOptions = (e) => {
            // var shown = e.target.attributes.getNamedItem('data-show-user-options').value;
            if (parseInt(userOptions) === parseInt(0)) {
                // e.target.setAttribute('data-show-user-options',1);
                setshowuserOptions(1)
            } else {
                //  e.target.setAttribute('data-show-user-options',0);
                setshowuserOptions(0)
            }
        }
        const GetSchools = async (value, reset = '') => {

             (async function() { 

             setschoolsOptions  ( await GetSchoolsCommon())

             })()


            var postdata = {
                active: 1
            };
            const response = await fetch(Url + "getschools", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        setschoolsList(data.data);
                    } else {}
                })
        };
        const GetSubjects = async (value, reset = '') => {


            

            (async function() { 

             setsubjectsOptions  ( await GetSubjectsCommon())

             })()

            var postdata = {
                active: 1
            };
            const response = await fetch(Url + "getsubjects", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        setsubjectsList(data.data);
                    } else {
                        ;
                    }
                })
        };
        const GetFollowers = async (value, reset = '') => {
            setfollowerslist([]);
            var postdata = {
                active: 1,
                userid: AkuserId
            };
            const response = await fetch(Url + "getfollowers", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success && data.data.length > 0) {
                        var followerdatavalues = data.data
                     
                                 setfollowerslist(followerdatavalues);
                            
                    } else {}
                })
        };
        const GetFollowings = async (value, reset = '') => {
             setfollowingslist([]);

            var postdata = {
                active: 1,
                userid: AkuserId
            };
            const response = await fetch(Url + "getfollowings", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        var followingsdatavalues = data.data
                     
                                 setfollowingslist(followingsdatavalues);
                           
                    } else {}
                })
        };
        const GetSavedlists = async (value, reset = '') => {
            var postdata = {
                active: 1,
                userid: AkuserId,
                usertype: value,
                list: 1
            };
            const response = await fetch(Url + "get-saved", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        if (data.data.length == 0) {
                            setcheckslrecordsLength('Nothing Saved')
                        }
                        //setsavedpostsList(data.data);
                        setstudentsavedpostsList([])
                        setteachersavedpostsList([])
                        if (value == 1) {
                            setteachersavedpostsList(data.data)
                        }
                        if (value == 2) {
                            setstudentsavedpostsList(data.data)
                        }
                    } else {
                        setcheckslrecordsLength('Nothing Saved')
                    }
                })
        };


 const MobileNumberValidation=(text)=>{
   var returnval
   if(text.split('').length > 10 || ( /^\d+$/.test(text))  == false){

      returnval = false
  }  else{
    returnval = true;
  }
 
   return returnval;
 
}

        const handleUserInput = (e) => {
            setshowUpdateProfileButton(1)
            RemoveNotificationAfterName();
            var name = e.target.name;
            var value = e.target.value;
            switch (name) {
                case 'name':
                    setName(value);
                    break;
                case 'lastname':
                    setLastname(value);
                    break;
                case 'school':
                    setSchool(value);
                    break;
                case 'standard':
                    setStandard(value);
                    break;
                case 'university':
                    setUniversity(value);
                    break;
                case 'pincode':
                    setPincode(value)
                    break;
                case 'mobile':
                    ( MobileNumberValidation(value) == true) ? setMobileedit(value) : setMobileedit('');
                    ( MobileNumberValidation(value) == true) ? setMobile(value) : setMobile('');

                     
                    setshowUpdateProfileButton(0)
                    if (value != '' && value.length != 10) {
                        setshowUpdateProfileButton(0)
                    }
                    if (MobileNumberValidation(value) == true && value != '' && value.length == 10) {
                        setshowUpdateProfileButton(1)
                    }
                    break;
            }
            //  (email != '' && password != '') ? setformValid(true): setformValid(false)
        }
        const showShareProfile = async () => {
            setShowsharepopup(1)
        }
        const closeShareProfile = async () => {
            setShowsharepopup(0)
        }
        const showMypostvideo = async (id) => {
            document.getElementsByClassName('my-post-mob-img-' + id)[0].style.display = "none";
            document.getElementsByClassName('my-post-mob-video-' + id)[0].style.display = "block";
        }
        const [Showvideopopup, setShowvideopopup] = useState(0);
        const [videopostid, setvideopostid] = useState('');
        const openVideopopup = async (postid) => {
            setvideopostid(postid)
            setShowvideopopup(1)
        }
        const closeVideopopup = async () => {
            setShowvideopopup(0)
        }
        const DeletePost = async (id) => {
            if (window.confirm("Are you sure?")) {
                var postdata = {
                    'id': id,
                    userid: AkuserId
                };
                const response = await fetch(Url + "deletepost", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            postdata
                        }),
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            Notifyalert('success', data.message)
                            GetMyposts();
                            // setnotificationtype('success')
                            //        setnotification(data.message)
                        } else {}
                    })
            }
        }
        const SearchFollowerUsers = async (e) => {
            var term = e.target.value;
            var show_boxes = document.getElementsByClassName('follower-single-sec')
            Array.prototype.forEach.call(show_boxes, function(el) {
                el.classList.add("display_none")
                var userbox = el.attributes.getNamedItem('data-attr-name').value;
                if (userbox.indexOf(term) != -1) {
                    el.classList.remove("display_none")
                }
            });
        }
        const SearchFollowingUsers = async (e) => {
            var term = e.target.value;
            var show_boxes = document.getElementsByClassName('following-single-sec')
            Array.prototype.forEach.call(show_boxes, function(el) {
                el.classList.add("display_none")
                var userbox = el.attributes.getNamedItem('data-attr-name').value;
                if (userbox.indexOf(term) != -1) {
                    el.classList.remove("display_none")
                }
            });
        }
        const updateFollower = async (e, username) => {
            var followed = e.target.attributes.getNamedItem('data-update-followed').value;
            if (parseInt(followed) === parseInt(0)) {
                e.target.setAttribute('data-update-followed', 1);
                e.target.innerHTML = 'Following'
                Socketcall('on', 'joinuser', {
                    token: username
                })
                Socketcall('emit', 'newfollow', {
                    token: username,
                    from: Akusername
                })
            } else {
                e.target.setAttribute('data-update-followed', 0);
                e.target.innerHTML = 'Follow'
            }
            var postdata = {
                following: username,
                follower: AkuserId
            }
            const response = await fetch(Url + "update-follower", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        Getprofile()
                        GetFollowings()
                        //   Notifyalert('success', data.message)
                    }
                })
        }
        const removeFollower = async (e, username) => {
            var postdata = {
                myfollower: username,
                currentuser: AkuserId
            }
            const response = await fetch(Url + "remove-follower", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    GetFollowers()
                    Getprofile()
                    //   Notifyalert('success', data.message)
                })
        }
        const handleNotifyUserInput = (e) => {
            var name = e.target.name;
            var checkedvalue = e.target.attributes.getNamedItem('data-attr-checked').value;
            if (checkedvalue == 1) {
                var value = 0;
                e.target.setAttribute('data-attr-checked', 0);
                e.target.classList.remove('checked');
            } else {
                var value = 1;
                e.target.setAttribute('data-attr-checked', 1);
                e.target.classList.add('checked');
            }
            switch (name) {
                case 'notifylikes':
                    setNotifylikes(value);
                    break;
                case 'notifycomments':
                    setNotifycomments(value);
                    break;
                case 'notifyfollows':
                    setNotifyfollows(value);
                    break;
            }
            //  (email != '' && password != '') ? setformValid(true): setformValid(false)
        }
        useEffect(() => {
            Getprofile()
            GetMyposts()
            GetSchools()
            GetSubjects()
            GetFollowers()
            GetFollowings();
            //GetSavedlists()
            if (tabtype == 'preferences') {
                showNotifications();
            }
            // checkSetAgreementsview();
        }, [setNotifylikes, setNotifycomments, setNotifyfollows, setsavedlisttype, setsavedpostsList, savedpostsList]);
 

  return (
      <div>
           <Header pageInfo="My Profile" />


     <div>
   <div id={(Showsharepopup == 1 ) ? 'overlay-hidden':'' } className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 page-wrapper-section">
      <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
         <Sidebar showBottomNavbar={BottomNavbarstatus} />
      </div>
      <div className="col-md-9 col-lg-9 col-sm-12 col-xs-9 full-section col-12">
         <div className="page-content">
            <div className="profile-container">
               {(myProfile == 1) ? 
               <div className="my-profile-section"  >
                  <div className="page-container-section"  >
                    {/* <h1 className="page-container-title cur-poi show-nav"  > <span className="goto-section  mob-view-back display_none" > <Link  to={-1}>  <img src={angleLeft} /> </Link>   </span>  {username} <span className="prof-nav-icon" data-show-user-options="0" onClick={(e) => showuserOptions(e)} ><i className="fa fa-navicon"> </i> </span></h1>
                        */ }


                    <h1 className="page-container-title cur-poi show-nav"  > 


                    <span className="goto-section  mob-view-back display_none" > <Link  to={-1}>  <img src={angleLeft} /> </Link>  


                     </span>  {username}


 
 <a className="f-dropdown-link prof-nav-icon"><i className="fa fa-navicon"> </i>

                        <ul className="f-prof-dropdown">

                           <li onClick={showNotifications} > <a >Notification Settings </a> < /li>
                                 <li onClick={showChangepassword} > <a>Reset Password </a> < /li>
                                 <li onClick={showContactus} ><a> Contact Us</a></li>
                                 <li><Link to={frontUrl+'newad'} >Advertise My Product </Link></li>
                        </ul>
                       </a> 

                      </h1>
                    
                      

                      

                     {(userOptions == 1) ? 
                     <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row user-options-main-sec  ">
                        <div className="col-md-2 col-lg-5 col-sm-3 col-xs-3 col-9 user-options-sec">
                           <div className="user-options">
                              <ul>
                                 <li onClick={showNotifications} > <a >Notification Settings </a> < /li>
                                 <li onClick={showChangepassword} > <a>Reset Password </a> < /li>
                                 <li onClick={showContactus} ><a> Contact Us</a></li>
                                 <li><Link to={frontUrl+'newad'} >Advertise My Product </Link></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     :''}
                  </div>
                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row prof-dat-sec">
                     <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 col-3"> 
                     <div className="profile-pic-section"> 
                        <img src={UserProfilepicture.preview} className="profile-pic" /> 
                        </div>
                     </div>
                     <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 col-3 ">
                        <p className="profile-prop-sec">{postscount}</p>
                        <p className="profile-value-sec">Posts</p>
                     </div>
                     <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 col-3 c-point" onClick={showFollowers}>
                        <p className="profile-prop-sec">{followerscount}</p>
                        <p className="profile-value-sec">Followers</p>
                     </div>
                     <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 col-3 c-point" onClick={showFollowings}>
                        <p className="profile-prop-sec">{followingscount}</p>
                        <p className="profile-value-sec">Following</p>
                     </div>
                  </div>
                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row prof-btn-main-sec">
                     <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6 col-6 prof-btn-sec">
                        <button type="submit" className=" me-1 mb-1 profile-main-btn" onClick={showeditProfile}  >Edit Profile</button>
                     </div>
                     <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6 col-6  prof-btn-sec">
                        <button type="submit" className=" me-1 mb-1 profile-main-btn"  onClick={showShareProfile}  >Share Profile</button>

 
 
                     </div>
                  </div>
                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row">
                     <h2 className="page-container-sub-title cur-poi padding-0"  > <span className="goto-section" >    </span> My Uploads</h2>
                  </div>
                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row my-uploads-sec">
                     <ul>
                        <li className="active"><a> Posts</a></li>
                        {/*<li> Photos</li>*/}
                        <li onClick={showSaved}> Saved</li>
                     </ul>

                     <div className="my-uploads-tab"> 

               {(mypostsList.length == 0) ?  <div className="no-records">      {checkprecordsLength} </div> :

                    <div className="my-post-list-section col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row">
                       {mypostsList.map((post) => (
                       <div className="col-md-4 col-lg-4 col-sm-4 col-xs-6 col-6  my-post-single">
                          <div className=" my-post-single-img-sec">
                             <img src=   {post.thumbnail} className={"my-post-mob-img my-post-mob-img-"+ post._id } onClick={(postId)=>showMypostvideo(post._id)} />  
                             <img src=   {post.thumbnail} className={"my-post-desk-i-img my-post-desk-i-img-"+ post._id } onClick={(postId)=>openVideopopup(post._id)} />  
                             <div  className={"my-post-mob-video my-post-mob-video-"+ post._id } >
                             <iframe width="100%" height="250" src={post.videolink+'?rel=0&controls=1&showinfo=0&modestbranding=1'} title="How to setup whatsapp cloud API" frameborder="0"  allowfullscreen="allowfullscreen"></iframe>
                          </div>
                          <div className="my-post-single-topic">
                             {post.topic}  
                             {/*
                             <Link to={editPosturl+post._id}>
                             <span> <i className="fa fa-edit"> </i> </span> </Link> */}
                             <span onClick={(postid)=>DeletePost(post._id)} className="cur-poi" >  <i className="fa fa-trash-o"> </i> </span>
                             <br/>
                             {(post.status == 1) ? <span>Status : Active </span> :  <span>Status : Pending </span>} 
                          </div>
                       </div>
                       <div className=" my-post-single-action">
                       </div>
                    </div>
                    ))}
                    </div>
                    }
                    </div>
                    </div>
                    </div>
               : ''}
               {(editProfile == 1) ? 
               <div className="edit-profile-section"  >
                  <div className="page-container-section"  >
                     <h1 className="page-container-title cur-poi" onClick={showmyProfile} > <span className="goto-section" ><img src={angleLeft} /></span> Edit Profile</h1>
                  </div>
                  <div className="page-container-image-section"  >
                     <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row">
                           <img className="edit-profile-img" src={UserProfilepicture.preview} />
                           <a className="edit-prof-pic" onClick={showeditProfilepic} > Change Profile Picture</a>
                        </div>
                     </div>
                  </div>
                  <input    onChange={(e) => handleUserInput(e)} autoComplete="off" value={name}  type="text" name="name" className="form-control profile-input mb-25 MustEnterField"   placeholder="Name"    />
                  <input     onChange={(e) => handleUserInput(e)} autoComplete="off" value={lastname}  type="text" name="lastname" className="form-control profile-input mb-25 MustEnterField"   placeholder="Last Name"   required/>
                  <input   onChange={(e) => handleUserInput(e)} autoComplete="off" value={mobiledit}  type="text" name="mobile" className="form-control profile-input mb-25"   placeholder="Mobile Number"   required/>
                  

                    <Select className="form-control   MustEnterField profile-input pad-5px" 
                    name={"school"}
                    value={selectedSchoolOption}
                    onChange={handleChange}
                    options={Schooloptions}
                    />


                    {/* 
                  <select  onChange={(e) =>
                     handleUserInput(e)} name="school" className="form-control profile-input mb-25 MustEnterField"  >
                     <option value="">Select School Group / School Name*</option>
                     {schoolsList.map((schoolsingle) => (
                     <option value={schoolsingle._id}  selected={school == schoolsingle._id ? 'selected':''} >{schoolsingle.name+' - '+schoolsingle.pincode }</option>
                     ))}    
                  </select> */ }
 {/* 
                  <select onChange={(e) =>
                     handleUserInput(e)} name="subject" className="form-control profile-input mb-25 MustEnterField"  >
                     <option value="">Select Subject*</option>
                     {subjectsList.map((subjectsingle) => (
                     <option value={subjectsingle._id}  selected={subject == subjectsingle._id ? 'selected':''} >{subjectsingle.name }</option>
                     ))}    
                  </select>

                  <select  onChange={(e) =>
                     handleUserInput(e)} name="standard" className="form-control profile-input mb-25 MustEnterField"   >
                     <option value="">Standard*</option>
                     <option value="1"  selected={standard == 1 ? 'selected':''}  >1st</option> 
                  </select>*/ }

                  {(Akusertype == '1') ?


                  <Select className="form-control   MustEnterField profile-input pad-5px" 
                    name={"subject"}
                    value={selectedSubjectOption}
                    onChange={handleChangeSubject}
                    options={Subjectoptions}
                    />

                  
                  : '' }
                  {(Akusertype == '2') ?

                   <Select className="form-control   MustEnterField profile-input pad-5px" 
                    name={"standard"}
                    value={selectedStandardOption}
                    onChange={handleChangeStandard}
                    options={Standardoptions}
                    />

                  
                  : '' }
                  <input  onChange={(e) => handleUserInput(e)} autoComplete="off" value={university}   type="text" name="university" className="form-control profile-input mb-25 MustEnterField"   placeholder="University"   required/>
                  <input  onChange={(e) => handleUserInput(e)} autoComplete="off" value={pincode}   type="text" name="pincode" className="form-control profile-input mb-25 MustEnterField"   placeholder="Pincode"   required/>
                  {(showUpdateProfileButton == 1) ?  
                  <button type="submit" className=" me-1 mb-1 edit-profile-main-btn" onClick={Updateprofile}  >Submit</button>
                  :
                  <button type="submit" className=" me-1 mb-1 edit-profile-disabled-btn"   >Submit</button>
                  }
               </div>
               : ''}
               {(editProfilepic == 1) ? 
               <div className="update-profile-pic-section"  >
                  <div className="page-container-section"  >
                     <h1 className="page-container-title cur-poi" onClick={showeditProfile} > <span className="goto-section" ><img src={angleLeft} /></span> Update Profile Picture</h1>
                  </div>
                  <div className="page-container-image-section text-center"  >
                     {(UserProfilepicture.preview !='') ?
                     <img className="edit-profile-img" src={UserProfilepicture.preview} /> : '' }
                  </div>
                  <input className="form-control" onChange={handlePictureChange}  type="file" name="userprofilepicture" placeholder="Email"      
                     required/>
                  <br/>
                  {(UserProfilepictureedit.preview !='') ?
                  <img alt="Profile" className="img-responsive w-100px profile-img-preview mb-25 " src={UserProfilepictureedit.preview} />
                  : '' }
                  <button    className=" me-1 mb-1 edit-profile-main-btn"  onClick={Updateuserprofilepicture}  >Submit</button>
               </div>
               : ''}
               {(Followerslistview == 1) ? 
<div   className="newmodal" id="followerlistmodal">
   <div className="newmodal-dialog">
      <span id="modalClose" className="close no-select"><i className="fa fa-times" aria-hidden="true"></i></span>
      <div className="newmodal-content" onClick={(e)=>
         stoppropagation(e)}>
         <h3 >Followers </h3>
         <div className="update-profile-pic-section follower-list-section"  >

        <div className="search-follower-sec" >

         <input type="text" className="search-follower-list" onKeyUp={(e)=>SearchFollowerUsers(e)}  placeholder="Search"/>
        </div>

         
        <div className="modal-follow-wrapper">
            {Followerslist.map((followersingle) => (
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row follower-single-sec " data-attr-name={followersingle.username}>
               <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 col-4 ">
                  <div className="follow-profile-pic-sec"> 
                   <Link to={userProfileurl+followersingle.username}>   <img alt="Profile" className="img-responsive" src={followersingle.profilepicture} /> </Link>
                  </div>
               </div>
               <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 col-4    follower-info">
               <Link to={userProfileurl+followersingle.username}>   {followersingle.username} </Link>
               </div>
               <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 col-4 follower-info-btn ">
                  <button type="submit" className=" me-1 mb-1 remove-follow-main-btn"  onClick={(e,username)=>removeFollower(e,followersingle.username)}  >Remove</button>
               </div>
            </div>
            ))}
            </div>
         </div>
      </div>
   </div>
</div>

               
               : ''}
               {(Followingslistview == 1) ? 

                
 <div   className="newmodal" id="followinglistmodal">
   <div className="newmodal-dialog">
      <span id="modalClose" className="close no-select"><i className="fa fa-times" aria-hidden="true"></i></span>
      <div className="newmodal-content" onClick={(e)=>
         stoppropagation(e)}>
         <h3 >Following </h3>
         <div className="update-profile-pic-section following-list-section"  >

          <div className="search-following-sec" >

         <input type="text" className="search-following-list" onKeyUp={(e)=>SearchFollowingUsers(e)}  placeholder="Search"/>
        </div>
            <div className="modal-follow-wrapper">
            {Followingslist.map((followingsingle) => (
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row following-single-sec "  data-attr-name={followingsingle.username}>
               <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 col-4 ">
                  <div className="follow-profile-pic-sec"> 
                    <Link to={userProfileurl+followingsingle.username}>   <img alt="Profile" className="img-responsive" src={followingsingle.profilepicture} /> </Link>
                  </div>
               </div>
               <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 col-4    follower-info">
                <Link to={userProfileurl+followingsingle.username}>    {followingsingle.username}</Link>
               </div>
               <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 col-4 follower-info-btn ">
                  <button type="submit" className=" me-1 mb-1 remove-follow-main-btn"  onClick={(e,username)=>updateFollower(e,followingsingle.username)} data-update-followed="1"  >Following</button>
               </div>
            </div>
            ))}
            </div>
         </div>
      </div>
   </div>
</div>


                
               : ''}
               {(Savedlistview == 1) ? 
               <div className="update-profile-pic-section"  >
                  <div className="page-container-section"  >
                     <h1 className="page-container-title cur-poi" onClick={showmyProfile} > <span className="goto-section" ><img src={angleLeft} /></span> Saved</h1>
                  </div>

                   <div className="saved-info-tabs" >
                  <ul>
                     <li  onClick={ShowStudentsavedlist}   > <a className={Studentactivesavedlist == 1 ? 'active' : ''} >  Students</a> </li>
                     <li> <a className="save-pipeline"  > &nbsp;</a> </li>
                     <li  onClick={ShowTeachersavedlist}  ><a className={Teacheractivesavedlist == 1 ? 'active' : ''} >  Teachers </a> </li>


                      
                  </ul>


                  {/*single**/}

                  {(studentsavedpostsList.length ==  0 && Studentactivesavedlist == 1 )  ?   <div className="no-records">{checkslrecordsLength}</div> : 
                  <div>

                 {studentsavedpostsList.map((post) => (

                 <div className="saved-post-list-section row">  
                 <Singlepost postid={post._id} />
                 </div>
                  
                  ))}
                  </div>
                  }
                  {/*single**/}

                     {(teachersavedpostsList.length ==  0 && Teacheractivesavedlist == 1 )  ?  <div className="no-records"> {checkslrecordsLength} </div>: 

<div>
                   {teachersavedpostsList.map((post) => (

                 <div className="saved-post-list-section row">  
                 <Singlepost postid={post._id} />
                 </div>
                  
                  ))}
 </div>
                   }
              



                  {/*single**/}


               </div>

               </div>
               : ''
               }
               {(Contactview == 1) ? 
               <div className="contact-us-section"  >
                  <div className="page-container-section"  >
                     <h1 className="page-container-title cur-poi" onClick={showmyProfile} > <span className="goto-section" ><img src={angleLeft} /></span> Contact Us</h1>
                  </div>
                  <form autoComplete="off" id="ContactForm" >
                     <input value={contactemail}  onChange={(e) => setcontactEmail(e.target.value)} autoComplete="off"    type="text" name="contactemail" className="form-control profile-input mb-25 MustContactEnterField"   placeholder="Enter Email Address*"    />
                     {/*<textarea onChange={(e) => setcontactMessage(e.target.value)} name="contactmessage" placeholder="Enter your message*" rows="10" className="textarea-profile-input MustContactEnterField mb-25"></textarea> */ }

                      <ReactQuill value={contactmessage}  onChange={ setcontactMessage } name="contactmessage" placeholder="Enter your message*" rows="10" className="textarea-contact-input MustContactEnterField mb-25"  />
                     <button type="submit" className=" me-1 mb-1 edit-profile-main-btn"  onClick={SubmitContactus}  >Submit</button>
                  </form>
               </div>
               : ''
               }
               {(Notificationsview == 1) ? 
               <div className="notifications-setting-section"  >
                  <div className="page-container-section"  >
                     <h1 className="page-container-title cur-poi" onClick={showmyProfile} > <span className="goto-section" ><img src={angleLeft} /></span> Notification Settings</h1>
                  </div>
                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row padding-25  ">


 

                     <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2 col-4  preference ">
                     <label className="switch">
                      <input type="checkbox" id="notifyliketoggle" name="notifylikes" data-attr-checked={notifylikes ==1 ? '1':''} className={notifylikes ==1 ? 'checked':''}   onClick={(e)=>handleNotifyUserInput(e)} />
                      <span className="slider round"></span>
                    </label>

                      </div>
                     <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6 col-8  text-left  ">
                        <label htmlFor="notifylikes" className="text-left text-white">Notify me for post likes </label>
                     </div>


                  </div>
                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row padding-25 ">
                     <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2 col-4 preference  ">

                     <label className="switch">
                      <input type="checkbox" id="notifycommenttoggle" name="notifycomments"  data-attr-checked={notifycomments ==1 ? '1':''} className={notifycomments ==1 ? 'checked':''}   onClick={(e)=>handleNotifyUserInput(e)} />
                      <span className="slider round"></span>
                    </label>

                      </div>
                     <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6 col-8  text-left  ">
                        <label htmlFor="notifycomments" className="text-left text-white">Notify me for post comments </label>
                     </div>
                  </div>
                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row padding-25 ">
                     <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2 col-4  preference ">

                      <label className="switch">
                      <input type="checkbox" id="notifyfollowstoggle" name="notifyfollows"  data-attr-checked={notifyfollows ==1 ? '1':''} className={notifyfollows ==1 ? 'checked':''}   onClick={(e)=>handleNotifyUserInput(e)} />
                      <span className="slider round"></span>
                    </label>

                      </div>
                     <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6 col-8  text-left  ">
                        <label htmlFor="notifyfollows" className="text-left text-white">Notify me for new follows </label>
                     </div>
                  </div>
                  <button type="submit" className=" me-1 mb-1 edit-profile-main-btn"  onClick={Updatenotificationsettings}  >Submit</button>
               </div>
               : ''
               }
               {(Changepasswordview == 1) ?  
               <div className="change-password-setting-section"  >
                  <div className="page-container-section"  >
                     <h1 className="page-container-title cur-poi" onClick={showmyProfile} > <span className="goto-section" ><img src={angleLeft} /></span> Change Password</h1>
                  </div>
                  <form autoComplete="off" id="PasswordForm" >
                     <input  onChange={(e) => setoldpassword(e.target.value)} autoComplete="off"    type="password" name="oldpassword" className="form-control profile-input mb-25 MustNewPasswordEnterField"   placeholder="Current Password*"    />
                     <input  onChange={(e) => setnewpassword(e.target.value)} autoComplete="off"    type="password" name="newpassword" className="form-control profile-input mb-25 MustNewPasswordEnterField"   placeholder="New Password*"    />
                     <input  onChange={(e) => setconfirmnewpassword(e.target.value)} autoComplete="off"    type="password" name="confirmnewpassword" className="form-control profile-input mb-25 MustNewPasswordEnterField"   placeholder="Confirm New Password*"    />
                     <button type="submit" className=" me-1 mb-1 edit-profile-main-btn"  onClick={Updatepassword}  >Submit</button>
                  </form>
               </div>
               : ''
               }










                
            </div>
         </div>
      </div>
   </div>



        {(Showsharepopup == 1) ?  


            <div className="share-modal-box">

            <div className="share-modal-bg">

                            <div className="share-modal-close">

                                    <button  onClick={closeShareProfile} > X </button>
                              </div>

        <Share shareurl={shareurl} />



        

             </div>
            </div> : '' }


                 {(Showvideopopup == 1) ?  


            <div className="video-modal-box">

            <div className="video-modal-bg">

                            <div className="video-modal-close">

                                    <button  onClick={closeVideopopup} > X </button>
                              </div>

        <Video videopostid={videopostid} />



        

             </div>
            </div> : '' }

</div>
 
</div>
 
  );
};
export default Profile;