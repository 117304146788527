import React, {useEffect, useState } from "react";
 
 import {Url, CommonNotify,UrlSplitter,checkEmptyUndefined,redirectPage,checkpermissionredirect} from './../../Api.js';

 import { GetSubjectsCommon,GetSchoolsCommon  } from './../../../frontend/Frontapi.js';
import Select from 'react-select';



import {    
   Allowchallenges_view ,
   Allowchallenges_add ,
   Allowchallenges_edit ,
   Allowchallenges_delete
} from './../../Permissions.js';

import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";
 

 

const Add = () => {

 
 
 



const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");

const[id] = useState(UrlSplitter(4));

const[title, setTitle] = useState(""); 


const[institutename, setInstitutename] = useState(""); 
const[institutelogo, setInstitutelogo] = useState(""); 


 const[status, setStatus] = useState(1); 

         const [submitLoading, setsubmitLoading] = useState(false);
const[startdatetime, setStartdatetime] = useState(""); 

 const[enddatetime, setEnddatetime] = useState(""); 


  const[prize, setPrize] = useState(""); 



 const Getchallenge = async (e) => {
  
  try{
     var postdata={_id:id};

     const response = await fetch(Url+"getchallenge", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
              
              var challengedata=data.data;
               setTitle(challengedata.title);
               setStatus(challengedata.status);
             
             setStartdatetime(challengedata.startdatetime);
               setEnddatetime(challengedata.enddatetime);
                
                setPrize(challengedata.prize);



                                setInstitutename(challengedata.institutename);

                                                                setInstitutelogo(challengedata.institutelogo);

                


                                setselectedSubjectOption({ value: challengedata.subject , label: challengedata.subjectname })
                setselectedStandardOption({ value: challengedata.standard , label: challengedata.standard })


                setSubject(challengedata.subject)
                setStandard(challengedata.standard)

          } else{
              //   
          }

           
       })



  } catch (error) {
      //
  }

 } 


 
  const Updatechallenge = async (e) => {
    e.preventDefault();
     setsubmitLoading(true)
    if(checkEmptyUndefined(title)=== false  || checkEmptyUndefined(prize) === false  ){
       setnotificationtype('error')
                    setnotification('* Fields Required')
                    setsubmitLoading(false)
                    return false

    }

    
     try {
    var  statusupdate = (typeof status == 'undefined'  || status == ''|| status == '1')?'1':0;
       var postdata={_id:id ,title :title,startdatetime :startdatetime,enddatetime :enddatetime,prize :prize  ,  subject : subject, standard:standard, status:statusupdate};
 
      const response = await fetch(Url+"updatechallenge", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
         setnotificationtype('success')
                    setnotification(data.message)
                      redirectPage('challenges')
          } else{
              setnotificationtype('error')
                    setnotification(data.message)
          }

           
       })
   setsubmitLoading(false)
    } catch (error) {
      
    }
  };


const handleChallengeInput = (e) => {
    setnotification("");
    var name = e.target.name;
    var value = e.target.value;
    console.log(value)
    switch (name) {
        case 'title':
            setTitle(value);
             
             
            break;
                case 'status':
            setStatus(value);
             break;

        case 'prize':
            setPrize(value);
 break;

             case 'startdatetime':
            setStartdatetime(value);
 break;
             case 'enddatetime':
            setEnddatetime(value);
              break;
             
           
        
        default:
            break;      
    }
 }

 

  /***Schools only***/
        const [school, setSchool] = useState("");
        const [Schooloptions, setschoolsOptions] = useState();
        const [selectedSchoolOption, setselectedSchoolOption] = useState({
            value: '',
            label: 'Select School*'
        })
        const emptyselectedSchoolOption = () => {
            setselectedSchoolOption({
                value: ' ',
                label: ' '
            })
        }
        const handleChange = (value) => {
            document.getElementsByClassName('select-school-input')[0].classList.remove('notify-input-missing');
            setSchool(value.value);
            setselectedSchoolOption(value)
        }
        /***Schools only***/
        /***Standard only***/
        const [standardsList, setstandardsList] = useState([]);
        const [standard, setStandard] = useState("");
        const [Standardoptions, setstandardOptions] = useState([{
                value: '5',
                label: '5th'
            },
            {
                value: '6',
                label: '6th'
            },
            {
                value: '7',
                label: '7th'
            },
            {
                value: '8',
                label: '8th'
            },
            {
                value: '9',
                label: '9th'
            },
            {
                value: '10',
                label: '10th'
            },
            {
                value: '11',
                label: '11th'
            },
            {
                value: '12',
                label: '12th'
            }
        ]);
        const [selectedStandardOption, setselectedStandardOption] = useState({
            value: '',
            label: 'Select Standard*'
        })
        const emptyselectedStandardOption = () => {
            setselectedStandardOption({
                value: ' ',
                label: ' '
            })
        }
        const handleChangeStandard = (value) => {
            document.getElementsByClassName('select-standard-input')[0].classList.remove('notify-input-missing');
            setStandard(value.value);
            setselectedStandardOption(value)
        }
        /***Standard only***/



        /**Subject***/
        const [subject, setSubject] = useState("");

        const [Subjectoptions, setsubjectsOptions] = useState();
        const [selectedSubjectOption, setselectedSubjectOption] = useState({ value: '', label: 'Select Subject*' }  )

           const  emptyselectedSubjectOption =() => {
          setselectedSubjectOption({ value: ' ', label: ' ' })
        }
        const handleChangeSubject = (value) => {
        document.getElementsByClassName('select-subject-input')[0].classList.remove('notify-input-missing');
        setSubject(value.value);
        setselectedSubjectOption(value)
        }

 

        /**Subject***/

           const GetSubjects = async (value, reset = '') => {

              (async function() { 

             setsubjectsOptions  ( await GetSubjectsCommon())

             })()


          }

        const GetSchools = async (value, reset = '') => {
            (async function() {
                setschoolsOptions(await GetSchoolsCommon())
            })()
        }
        const schooloptionselectbox = (event) => {
            setSchool('')
            emptyselectedSchoolOption()
        }


    
useEffect(() => {
    Getchallenge(id);
  GetSchools();
            GetSubjects();

       if(typeof id != 'undefined' && id !=''){
    checkpermissionredirect(Allowchallenges_edit)
}else{
    checkpermissionredirect(Allowchallenges_add)

}


  
  },[] ); 

   return (

       <div id="app">
            <Header title={typeof id !== 'undefined'?"Edit Challenge":"Add Challenge"} />

         <Sidebar activePage="Challenges" activeChildPage="Add" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage={typeof id !== 'undefined'?"Edit Challenge":"Add Challenge"}/>
                              <div className="row match-height">
            <div className="col-md-6 col-12 mx-auto">
            <div className="card">
                <div className="card-header">
                <h4 className="card-title">{typeof id !== 'undefined'?"Edit Challenge":"Add Challenge"}</h4>
                </div>
                <div className="card-content">
                <div className="card-body">
                    <form className="form form-horizontal">
                    <div className="form-body">
                        <div className="row">
                        <div className="col-md-4">
                            <label>Title <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={title}  type="text" name="title" placeholder="Title"  
                onChange={(e) => handleChallengeInput(e)}
                required/>
                        </div>



                          <div className="col-md-4">
                            <label>Subject <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                           
  <Select className="form-control   MustEnterField select-register-input select-subject-input" 
                     name={"subject"}
                     value={selectedSubjectOption}
                     onChange={handleChangeSubject}
                     options={Subjectoptions}
                     />
                        </div>



 <div className="col-md-4">
                            <label>Standard <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                          <Select className="form-control   MustEnterField select-register-input select-standard-input" 
                name={"standard"}
                value={selectedStandardOption}
                onChange={handleChangeStandard}
                options={Standardoptions}
                 />  
                     </div>




<div className="col-md-4">
                            <label>Start Date and Time <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
<input className="form-control" 
  type="datetime-local"
   name="startdatetime"
   value={startdatetime}
   onChange={(e) => handleChallengeInput(e)}

    />
 </div>


 <div className="col-md-4">
                            <label>End Date and Time <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
<input className="form-control" 
  type="datetime-local"
   name="enddatetime"
      onChange={(e) => handleChallengeInput(e)}

   value={enddatetime}
    />
 </div>



 





                        <div className="col-md-4">
                            <label>Status <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <select className="form-control"  name="status"  onChange={(e) => handleChallengeInput(e)}> 
                             <option value='1'  selected={1 == status ? 'selected' :''}  >Active</option>
                             <option value='0'  selected={0 == status ? 'selected' :''}  >InActive</option>
                            
                            </select>
                           
                        </div>





 <div className="col-md-4">
                            <label>Prize <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
<input className="form-control" 
  type="number"
   name="prize"

   onChange={(e) => handleChallengeInput(e)}

   value={prize}
    />
 </div>



<div className="col-md-12 display_flex  padding-zero padding-0 ">

 <div className="col-md-4  padding-zero">
                            <label>Institute Name <span className="field-required"></span></label>
                        </div>
                        <div className="col-md-8  form-group"> { (institutename != '')  ? institutename  : '' }
 
 </div>  

 </div> 

 <div className="col-md-12 display_flex  padding-zero padding-0 ">

 <div className="col-md-4 padding-zero">
                            <label>Institute Logo <span className="field-required"></span></label>
                        </div>
                        <div className="col-md-8 form-group  "> { (institutename != '') ?<img className="img-responsive" src={institutelogo}  />    : '' } 
 
 </div>  
</div> 


                     
                         {( submitLoading === false) ?  (
                        <div className="col-sm-12 d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary me-1 mb-1" onClick={Updatechallenge}>Submit</button>
                         </div>
                          ) : '' }
                        {
                             (notification!=='') ?  (
                              CommonNotify(notification,notificationtype)
                            ) :'' 
                           }


                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
            </div>


                                <Footer />
            </div>


         </div>

      </div>
                   
   
 
  );
};
export default Add;