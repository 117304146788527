import React, {useEffect, useState } from "react";
  import {UrlSplitter,convertTimeStamp,baseUrl,Url,ModalPopupDelete,UpdateNotify,Socketiourl,checkpermissionredirect} from './../../Api.js';

 import { Link } from 'react-router-dom';
 import io from 'socket.io-client';

 import {Socketcall} from '../../../socketcall/Socketcall.js';



  import {    
   Allowposts_view ,
   Allowposts_edit
 } from './../../Permissions.js';


import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";

//import Pagination from "../../includes/Pagination";

 
const Attendees = () => {

         const [id] = useState(UrlSplitter(3));
 const [challengewinnerpostid, setChallengewinnerpostid] = useState('');

 
 

 const [postsList, setpostsList] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);


//const [notification, setnotification] = useState("");
//const [notificationtype, setnotificationtype] = useState("");
const [nPages, setnPages] = useState(1);
    const [pageNumbers,setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));

 //const nPages = Math.ceil(postsList.length / recordsPerPage)

 const goToNextPage = () => {
             if(currentPage !== nPages) {
                setCurrentPage(currentPage + 1) 
                GetSubmissions()
             }
    }
    const goToPrevPage = () => {
        if(currentPage !== nPages){
            setCurrentPage(currentPage - 1)
             GetSubmissions()

        }  
   }


      const goToPage = (value) => {
           setCurrentPage(value)
          
             GetSubmissions(value)  
          
               
         
            
           

        }  

  const GetSubmissions = async (value ) => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)

    value = (typeof value !== 'undefined') ? value : currentPage;

      var postdata={'currentpage':value,'perpage':recordsPerPage,admin:1 , id:id};

        const response = await fetch(Url+"getchallengesubmissions", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
        setpostsList(data.data );
        setChallengewinnerpostid(data.winner)
        if(data.count > 1){
            //setnPages(data.count / recordsPerPage);
 
            setpageNumbers(  [...Array( Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1) )

        }
         
          } else{
                 
          }

           
       })


 
  }; 


          useEffect(() => {
   GetSubmissions();


         checkpermissionredirect(Allowposts_view)



  }, []);
   
     
  //pagination part

  return (
      <div id="app">
            <Header title="Attendees List" />

         <Sidebar activePage="Attendees" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage="Attendees"/>

{ModalPopupDelete()}
        
              <section className="section">
        <div className="card">
            <div className="card-header">
               <span>Attendees</span>
             </div>
            <div className="card-body">

                <table className='table table-striped' id="table1">
                    <thead>
                        <tr>
                              <th>Date</th>
                            <th>Username</th>

                         
 
                           
                             <th>Start Time</th> 
                            <th>End Time</th>
                           

                            <th>Questions Attended</th>
                            <th>Winner</th>


                             <th>Results</th>

                        </tr>
                    </thead>
                    <tbody>
                       {postsList.map((post) => (
                        <tr>
                           <td>  {  post.createddatetime   }   </td>
                               <td>{post.username}</td>


 

 
                         
                            <td>  {  post.startdatetime   }   </td>
                            <td>  {  post.enddatetime   }   </td>


                            <td>  {  post.questionsattended   }   </td>


                         <td>  {  post.winner   }   </td>

                         <td>
                          <Link className="cur-poi badge bg-success" to={baseUrl+'challengeuserresults/'+post.challengeid+'/'+ post.userid } > <i className="fa fa-eye"> </i> View</Link>
                           </td>

                        </tr>
                        
                           ))}
                        
                    </tbody>
                </table>
            </div>
        </div>
{  pageNumbers.length > 1 ? 

       <nav>
   <ul className='pagination pagination-primary justify-content-center'>
      <li className="page-item prev-paging-single">
         <a className="page-link" 
            onClick={goToPrevPage} 
            href='#'>
         Previous
         </a>
      </li>
       {pageNumbers.map(pgNumber => (
      <li key={pgNumber} 
      className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
      <a onClick={(e) => goToPage(pgNumber)  }  
      className='page-link' 
      href='#'>
      {pgNumber}
      </a>
      </li>
      ))}
      <li className="page-item paging-next-single">
         <a className="page-link" 
            onClick={goToNextPage}
            href='#'>
         Next
         </a>
      </li>
   </ul>
</nav>
: ''

}

    </section>

                <Footer />
            </div>


         </div>

      </div>
 
 
  );
};
export default Attendees;