import React, {
    useEffect,
    useState
} from "react";


import {
    Link
} from 'react-router-dom';
import { Rating } from 'react-simple-star-rating'



import {
    convertTimeStamp,
    baseUrl,
    Url,
    ModalPopupDelete,
    UpdateNotify,
    UrlSplitter
} from './../../Api.js';

import { Getpostimage,Getuserimage } from '../../../frontend/GetDynamicImages.js';

import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";
import Footer from "../../includes/Footer";
//import Pagination from "../../includes/Pagination";
const Listcomments = () => {
        const [id] = useState(UrlSplitter(3));

         const [topic, setTopic] = useState();
        const [caption, setCaption] = useState();
        const [reviews, setreviews] = useState([]);
 

  const [hostname, setHostname] = useState();
 

        const Getreviews = async () => {
            var postdata = {
                 streamid: id 
            }
            const response = await fetch(Url + "get-stream-reviews", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        postdata
                    })
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        var postdata = data.data;
                        setTopic(postdata.topic)
                         setCaption(postdata.caption)
                         setHostname(postdata.hostname)
                        setreviews(postdata.reviews)
                   
                        //Notifyalert('success', data.message)
                        // document.getElementById("NewPostForm").reset()
                    } else {}
                })
        }


       


        useEffect(() => {
            Getreviews();
        }, []);

  return (
      <div id="app">
            <Header title="Reviews" />

         <Sidebar activePage="Reviews" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage="Reviews"/>
   <div className="row match-height">
            <div className="col-md-6 col-12 mx-auto">
{ModalPopupDelete()}
        
              <section className="section">
        <div className="card">


            <div className="card-header">
            <Link to={-1}> <i className="fa fa-angle-left"></i> Back to Listing</Link><br/><br/>

              <p className="comment-post-topic">Topic : {topic}</p>


               

       <p className="comment-post-caption">Caption : {caption}</p>


        <p className="comment-post-topic">Hostname : {hostname}</p>

               <span>Reviews</span>
             </div>
            <div className="card-body">
 

     
    <div className="ad-post-comments-section">
             {reviews.map((reviewsingle) => (

                     <div className="post-comments-single curs-no">

                       
                      <div className="post-comment-single-user-img" > 
                       <img src={reviewsingle.userprofilepicture } className="post-comment-user-img" /> 

                       <p className="post-comment-user-name" >{reviewsingle.username} </p>

                      </div>
                                            <div className="post-comment-single-user-info" >

                                              
                                             
   <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
      <div className="col-9 col-md-9 col-lg-9 col-sm-9 col-xs-9 col-xl-9">
         <p className=" text-left"> Did you enjoyed the session ? </p>
      </div>
      <div className="col-3 col-md-3 col-lg-3 col-sm-3 col-xs-3 col-xl-3"> 
         <Rating  initialValue={reviewsingle.favourenjoyed}
         />
      </div>
   </div>
   <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
      <div className="col-9 col-md-9 col-lg-9 col-sm-9 col-xs-9 col-xl-9">
         <p className=" text-left"> All questions were answered ? </p>
      </div>
      <div className="col-3 col-md-3 col-lg-3 col-sm-3 col-xs-3 col-xl-3"> 
         <Rating  initialValue={reviewsingle.questionsresponse}
         />
      </div>
   </div>
   <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
      <div className="col-9 col-md-9 col-lg-9 col-sm-9 col-xs-9 col-xl-9">
         <p className=" text-left"> How was the communication skill? </p>
      </div>
      <div className="col-3 col-md-3 col-lg-3 col-sm-3 col-xs-3 col-xl-3"> 
         <Rating  initialValue={reviewsingle.communicationskills}
         />
      </div>
   </div>
   <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
      <div className="col-9 col-md-9 col-lg-9 col-sm-9 col-xs-9 col-xl-9">
         <p className=" text-left"> All topics were covered? </p>
      </div>
      <div className="col-3 col-md-3 col-lg-3 col-sm-3 col-xs-3 col-xl-3"> 
          <Rating  initialValue={reviewsingle.topicscovered}
         />
      </div>
   </div>
   <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
      <div className="col-9 col-md-9 col-lg-9 col-sm-9 col-xs-9 col-xl-9">
         <p className=" text-left"> Would you recommend this teacher? </p>
      </div>
      <div className="col-3 col-md-3 col-lg-3 col-sm-3 col-xs-3 col-xl-3"> 
         <Rating  initialValue={reviewsingle.recommendation}
         />
      </div>
   </div>
   <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12 row">
      <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-12">
         <p className=" text-left"> <b>Feedback</b> </p>

         <p>{reviewsingle.feedback} </p>
         
      </div>
   </div>


    
 

                                             </div>


                          


                           
                      </div>

                      ))}
</div>






   </div>
        </div>
 

    </section>


   </div>
        </div>
 
                <Footer />
            </div>


         </div>

      </div>
 
 
  );
};


export default Listcomments;