import React, { useState ,useEffect} from "react";
 
 import {
    Link,
    useNavigate
} from "react-router-dom";

  import {  HeadBg,MidBg,FootBg,NewMidBg} from '../ImagesIcons.js';


 

// import Header from "../includes/Header";
// import Sidebar from "../includes/Sidebar";
// import Navbar from "../includes/Navbar";
// import Pagetitle from "../includes/Pagetitle";


// import Footer from "../includes/Footer";

import {SiteTextLogo,SiteLogo,frontUrl,redirectfrontPage,Redirectdashboard,Redirectlogin ,containerStart , containerEnd , AkuserId,AKcountsupdated} from '../Frontapi.js';



   import Signup from "../auth/Register"


   import Dashboard from "../pages/Dashboard"




 
const Home = () => {
  const navigate = useNavigate();

 const urlParams = new URLSearchParams(window.location.search);

const myParam = urlParams.get('referral');

   localStorage.setItem('AKreferralcode', myParam)
   

const [showSplash,setshowSplash]=useState(0)

const [showSignup,setshowSignup]=useState(0)

const [showDashboard,setshowDashboard]=useState(0)



const Redirect = () => {

            

                 setTimeout(function() {
                    setshowSplash(0)

              setshowSignup(0)
             
              if((AkuserId ==  null || AkuserId == '' || typeof AkuserId == 'undefined') ){
                setshowSignup(1)
              }

             
 
     }, 1000);
              
            
   }


   const ProceedNext =()=>{

setshowSplash(0);

  if( (AkuserId ==  null || AkuserId == '' || typeof AkuserId == 'undefined')  )  {
setshowSignup(1)

  }   


  if(  (AkuserId !=  null && AKcountsupdated != null ) ) {   setshowDashboard(1) }  
  

   }
  
       useEffect(() => {



 
   if((AkuserId !=  null && AKcountsupdated != null  )){
          setshowSplash(0)

                setshowDashboard(1)
              }else{

                if(window.location.search.substr(1)  == 'direct'){
  setshowSplash(0)
 setshowSignup(1)
 }else{
    setshowSplash(1)

 }

 
                 //   Redirect();
              }


  
  }, []);
   

  return (

      <div id="app">



      <div className=" full-section"> 

      {((AkuserId ==  null || AkuserId == '' || typeof AkuserId == 'undefined')  && showSplash == 1) ?  
      <div className="container-mb"> 

      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 full-section"> 


      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 row ">

        <div className="home-head-section">
            <img className="splash-home" src={FootBg} />
             </div>
      </div>
      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 row">
      <div className="home-mid-section">
      <img className="splash-home" src={SiteLogo} />
      </div>

      </div>
      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 row">
      <div className="home-mid-section">
      <img className="splash-home" src={SiteTextLogo} />
       </div>

      </div>
      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 row">
      <div className="home-bottom-section">


      <p className="register-login-inst mb-50"> We believe, for strong foundation good
education is the must. And today it is
very costly. Our platform solves this 
problem. We welcome teachers and 
student to visit our application</p>

</div>
 
</div>
      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 row">

<div className="home-bottom-section">
                <button className="signup-button bg-yellow-amigo"  onClick={ProceedNext}  >Next</button>  
                </div>
            
</div>


      </div>

              

      </div>:'' }

    {( (AkuserId ==  null || AkuserId == '' || typeof AkuserId == 'undefined') && showSignup == 1) ?    <Signup /> :'' }
    {(AkuserId !=  null && AKcountsupdated != null && showDashboard == 1) ?   <Dashboard /> : '' }


      </div>
   </div>
 
  );
};
export default Home;