import React, { useState, useEffect } from "react";
 import { Link, useNavigate } from "react-router-dom";


import {AkuserId, Akusertype, checkRedirectAfterLogin,RemoveNotificationAfterName, SetNotificationAfterName,SiteLogo, frontUrl,Url,Redirectdashboard,validEmailRegex,CommonNotify,checkEmptyUndefined,Notifyalert} from '../Frontapi.js';
import Header from '../includes/Header';
 import Footer from '../includes/Footer';

const Login = () => {

 

const navigate = useNavigate();
 
const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");
const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
const [emailError, setEmailerror] = useState(false);
const [emailvalid, setEmailvalid] = useState("");
const [passwordvalid, setPasswordvalid] = useState("");
const [formValid, setformValid] = useState(true);
const [validationSubmit, setvalidationSubmit] = useState(false);
const [submitLoading, setsubmitLoading] = useState(false);


const showhidepassword = (inputid) =>{
                var inputtype=  document.getElementById(inputid+"input").attributes.getNamedItem('type').value;
                var iconelemenent=  document.getElementById(inputid+"icon");
                if(inputtype == 'text'){
                document.getElementById(inputid+"input").setAttribute('type','password');
                iconelemenent.classList.remove('fa-eye');
                iconelemenent.classList.add('fa-eye-slash');
                }else{
                document.getElementById(inputid+"input").setAttribute('type','text');
                iconelemenent.classList.add('fa-eye');
                iconelemenent.classList.remove('fa-eye-slash');
                }
            }




const handleUserInput = (e) => {

  RemoveNotificationAfterName();

  if(e.keyCode === 13){
             signIn(e);
         }

     var name = e.target.name;
    var value = e.target.value;
    switch (name) {
        case 'email':
            setEmail(value);
           // setEmailvalid(true);
            validEmailRegex.test(value) ? setEmailerror(false) : setEmailerror(true)
            break;
        case 'password':
            setPassword(value)
           // setPasswordvalid(true);
            break;
    }
  //  (email != '' && password != '') ? setformValid(true): setformValid(false)
}




const getUseragreementcounts = async (AkuserIdVal) => {

    var userid = AkuserId;
    if (typeof AkuserIdVal != 'undefined' && AkuserIdVal != '' && AkuserIdVal != null) {
        userid = AkuserIdVal;
    }




    if (typeof userid != 'undefined' && userid != '' && userid != null) {
        var postdata = {
            userid: userid
        };
        var getUseragreement = await fetch(Url + "getuseragreement", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    postdata
                }),
            }).then(async (response) => response.json())
            .then(async (data) => {

                var agreedCounts = 0;

                if (data.success && data.data !== null) {
                    var userdata = data.data;


                    if (typeof userdata.postcontent != 'undefined' && userdata.postcontent != '0' && userdata.postcontent != '') {

                        agreedCounts = agreedCounts + 1;

                    }
                    if (typeof userdata.youtubepostcontent != 'undefined' && userdata.youtubepostcontent != '0' && userdata.youtubepostcontent != '') {

                        agreedCounts = agreedCounts + 1;

                    }
                    if (typeof userdata.adultpostcontent != 'undefined' && userdata.adultpostcontent != '0' && userdata.adultpostcontent != '') {

                        agreedCounts = agreedCounts + 1;

                    }
                    if (typeof userdata.afterpostcontent != 'undefined' && userdata.afterpostcontent != '0' && userdata.afterpostcontent != '') {

                        agreedCounts = agreedCounts + 1;

                    }
                    if (typeof userdata.kidscontent != 'undefined' && userdata.kidscontent != '0' && userdata.kidscontent != '') {

                        agreedCounts = agreedCounts + 1;

                    }
                    if (typeof userdata.advcontent != 'undefined' && userdata.advcontent != '0' && userdata.advcontent != '') {

                        agreedCounts = agreedCounts + 1;

                    }
                    if (typeof userdata.invitecontent != 'undefined' && userdata.invitecontent != '0' && userdata.invitecontent != '') {

                        agreedCounts = agreedCounts + 1;

                    }



                    return agreedCounts;

                } else {
                    return agreedCounts;

                }
            })


        return getUseragreement;
    }
}


const Redirectdashboard_Terms = async (AkuserIdVal, Akusertypeval) => {

    var counts = await getUseragreementcounts(AkuserIdVal);

    if (AkuserIdVal !== '' && AkuserIdVal !== null) {




        if (parseInt(Akusertypeval) === parseInt(1)) {


            if (parseInt(counts) === parseInt(7)) {

                localStorage.setItem('AKcountsupdated', 'updated')

                navigate("/dashboard")

            } else {

                navigate("/terms-agreement")




            }
        }
        if (parseInt(Akusertypeval) === parseInt(2)) {

            if (parseInt(counts) === parseInt(6)) {
                localStorage.setItem('AKcountsupdated', 'updated')


                navigate("/dashboard")

            } else {

                navigate("/terms-agreement-student")

            }
        }



    }

}
const signIn = async (e) => {
    e.preventDefault();

   // setsubmitLoading(true)
      if(checkEmptyUndefined(email) === false  || checkEmptyUndefined(password) === false){
     
    }

    /***Check Required***/
            var required_fields= document.getElementsByClassName('MustEnterField')
             if(document.getElementsByClassName('notify-input-text')[0]){
                document.getElementsByClassName('notify-input-text')[0].remove();
             } 
            var error=0;
            Array.prototype.forEach.call(required_fields, function(el) {
                        el.classList.remove('notify-input-missing');
                         //el.classList.add('mb-25');
                        if(el.value === '' && error == 0){
                        var inputname =  el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                      //   el.classList.remove('mb-25');
                        error=1;

                       

                        SetNotificationAfterName(el.name, inputname + ' required','noneed')
             }
             });
            if(  error==1){

             return false;
            }
             if(validEmailRegex.test(email) == false){
                

                // SetNotificationAfterName('email','Invalid Email')
             //   return false
            }
            
            /***Check Required***/

    setvalidationSubmit(true)
    var postdata = {
        email: email,
        password: password
    };
    try {
        const response = await fetch(Url + "userlogin", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    postdata
                }),
            }).then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    var userdata = data.data;
                    localStorage.setItem('AKuserId', userdata._id)
                    localStorage.setItem('AKusertype', userdata.usertype)
                    localStorage.setItem('AKusertype', userdata.usertype)
                    localStorage.setItem('AKusername', userdata.username)
                     //Notifyalert('success',data.message)
                      Redirectdashboard_Terms(userdata._id,userdata.usertype)
                    
                } else {
                    Notifyalert('error',data.message)
                  
                }
            })
              setsubmitLoading(false)
    } catch (error) {
        
    }
};
 
  useEffect(() => {
           
      window.scrollTo(0,0);



     Redirectdashboard_Terms(AkuserId, Akusertype);
  }, []);


  return (


    <div>

  <Header pageInfo="Login" />

    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section"> 

    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xs-9 full-section"> 
        {(AkuserId ==  null || AkuserId == '' || typeof AkuserId =='undefined'  ) ? 


    <div className="page-content">

    <div className="login-container"> 
        
        <div className="login-section"> 
 

<div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 row mb-30 padding-0" >
                     <div className="register-login-title-sec padding-0" >
                        <h1 className="login-title  ">Login</h1>
                     </div>
                     <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3" >
                        <img src={SiteLogo} />
                     </div>
                  </div>


          <input autoComplete="off"  type="text" name="email" className="form-control login-input mb-25 MustEnterField"   placeholder="Email / Username*"  
                              onKeyUp={(e) => handleUserInput(e)}
                           required/>


             <div className="password-input-box">               
             
              <input id="togglepasswordinput"  autoComplete="off"   className="form-control login-input mb-10 MustEnterField"   type="password" name="password" placeholder="Enter password*"  
                              onKeyUp={(e) => handleUserInput(e)}
                           required/>

                            <i id="togglepasswordicon" onClick={(inputname) =>showhidepassword('togglepassword')}  className=" fa fa-eye-slash"></i>
             </div>

             <p className="forgot-password-inst"  > <Link to={frontUrl+'forgot-password'} >Forgot password?</Link> </p>             
               
            <button className="login-button bg-yellow-amigo"  onClick={signIn}>Login</button>     
            

            <p className="login-signup-inst"  ><Link to={frontUrl+'?direct'} >Don't have an account?  <span> Register </span></Link> </p>             
                                           
        </div>

    </div>
    </div>
 : ''}

<Footer />
    </div>



    </div>
 

    </div>


 
 
  );
};
export default Login;