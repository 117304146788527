import React from "react";
import {    useLocation,    useNavigate} from "react-router-dom";
  

   import {SiteLogo, SiteBgLogo, SiteLogoDark,SiteFavicon} from '../frontend/ImagesIcons.js';

 

 import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

  import { Getprofileimage } from '../frontend/GetDynamicImages.js';


 
//import { createBrowserHistory } from 'history'

 



 const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const OwnerName = 'Amigoways Technologies'
const CurrentYear = new Date().getFullYear()
const userId=localStorage.getItem('userId')
const userEmail=localStorage.getItem('userEmail')
const userName=localStorage.getItem('userName')


const profilePictureStorage="../assets/images/"



    

const GetbaseUrl_Url = async(param) => { 
if(window.location.hostname === 'localhost'){
   if(param === 'base_url'){
        return   'http://localhost:3001/admin/' ;
     }
    if(param === 'url'){
         return   'http://localhost:5050/' ;
    } 
     if(param === 'socekturl'){
         return   'http://localhost:5060/' ;
    }
}else{
 

    if(param === 'base_url'){
         return   'https://www.academicgyan.com/admin/' ;
    }
    if(param === 'url'){
           return   'https://www.academicgyan.com/api/?url=' ;
    } 
      if(param === 'socekturl'){
          return   'https://wso.academicgyan.com/' ;
    }


}


}

const baseUrl=await GetbaseUrl_Url('base_url')
const Url=await GetbaseUrl_Url('url')
const Socketiourl=await GetbaseUrl_Url('socekturl')

const Redirectdashboard = () => {

 if(localStorage.getItem('userId') !== '' && localStorage.getItem('userId') !== null ){
     window.location.href=baseUrl+'dashboard';
     return false;
   }
}
const Redirectlogin = () => {
   if(localStorage.getItem('userId') === '' || localStorage.getItem('userId') === null){
  window.location.href=baseUrl+'login';
   }

}
const redirectPage = (page) => {
  window.location.href=baseUrl+page;

 // browserHistory(baseUrl+page)




}

 
const UrlSplitter = (params) => {
         const location = useLocation();
            var url =location.pathname;
            var urls=url.split('/')
            return urls[params]
}


const TriggerLogout = (data) => {

localStorage.removeItem('userId');
       
}

 

const GetProfilePicture = async(param) => {   

if(typeof userId != 'undefined' && userId!=null){ 

    var postdata={id:userId}
var result =  await  fetch(Url + "get-profile", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({postdata}),
    }).then(async (response) => response.json())
    .then(async  (data) => {
         if (data.success &&  data.data !== null) {
            var userdata = data.data;
               return   userdata;


         
        }  
    })
  try {
    if(param === 'name'){
        return result.name;
    }else{
        result=  result.profilepicture    ;
    return result;
}
   
   
    } catch (err) {
   return null;
  }
}
}
const ProfilePictureOutput= await Getprofileimage(await GetProfilePicture('profilepicture')) 
const CurrentUserName=await GetProfilePicture('name')
 
 

const ModalPopupDelete = (data,type) => {
  
  return (
     <div className="modal fade in" id="myModal" role="dialog">
    <div className="modal-dialog">
    
       <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal">&times;</button>
          <h4 className="modal-title">Modal Header</h4>
        </div>
        <div className="modal-body">
          <p>Some text in the modal.</p>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>
      
    </div>
  </div>
    )
}

const CommonNotify = (data,type) => {
  
  if(type === 'success') {
    // NotificationManager.success(data);
  }
   if(type === 'error') {
   //  NotificationManager.error(data);
  }


   
 

  return (
    <div>   
    <div id="alert" className={type === 'success' ? 'alert alert-success' : 'alert alert-danger'} >{data} </div>
    </div>
    )
 
}



const UpdateNotify = (message,type) => {

     if( type  == 'error'){
         NotificationManager.error(message);
    }else{
          NotificationManager.success(message);
         
    }



  
}



const DeleteNotify = (message) => {

     if(typeof message !='undefined'){
         NotificationManager.error(message);
    }else{
          NotificationManager.success('Deleted successfully');
          setTimeout(function(){
                  window.location.reload()
          },500)
    }



  
}


const checkEmptyUndefined=(data)=>{

if(typeof data === 'undefined' || data === ''){
  return false
}
return true

}

const convertTimeStamp=async(timestampVal)=>{

      // Months array
var months_arr = ['January','February','March','April','May','June','July','August','September','October','November','December'];

// Convert timestamp to milliseconds
var date = new Date(timestampVal*1000);

// Year
var year = date.getFullYear();

// Month
var month = months_arr[date.getMonth()];

// Day
var day = date.getDate();

// Hours
var hours = date.getHours();

// Minutes
var minutes = "0" + date.getMinutes();

// Seconds
var seconds = "0" + date.getSeconds();

// Display date time in MM-dd-yyyy h:m:s format
var fulldate = month+' '+day+'-'+year+' '+hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

// final date
var convdataTime = month+' '+day;
 

  return  convdataTime    
}
 





const CheckAdminuserExists = async() => {   


var postdata={  userid:userId , useremail : userEmail, username:userName }
var result =  await  fetch(Url + "check-a-user", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postdata
        }),
    }).then(async (response) => response.json())
    .then(async  (data) => {
    
    if(!data.success){
        TriggerLogout(); 
    }
         


         
         
    })

//
}

const CheckUserExistsv=await CheckAdminuserExists()




const checkpermissionredirect = async(value) =>{

 
 }



const GetSchoolsCommon = async() => {   


  var postdata = {active:1};
            const response = await fetch(Url + "getschools", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                       

                        var schooldata= data.data ;

var Schooloptions=[];
  Object.keys(schooldata).forEach(function(key) {

   Schooloptions.push({ value: schooldata[key]._id, label:  schooldata[key].name + ' - '+schooldata[key].pincode})  ;

});

  return Schooloptions;
                             // setschoolsOptions(Schooloptions);

                     } else {
                        ;
                    }
                })

                return response;

                }


 
const GetSubjectsCommon = async() => {   


  var postdata = {active:1};
            const response = await fetch(Url + "getsubjects", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                       

                        var subjectdata= data.data ;

var Subjectoptions=[];
  Object.keys(subjectdata).forEach(function(key) {



    Subjectoptions.push({ value: subjectdata[key]._id, label:  subjectdata[key].name  })  ;

});

  return Subjectoptions;
 
                     } else {
                        ;
                    }
                })

                return response;

                }
export {SiteBgLogo,GetSubjectsCommon,GetSchoolsCommon,checkpermissionredirect,userEmail,userName , Socketiourl,SiteLogoDark,convertTimeStamp,UpdateNotify,DeleteNotify,redirectPage,checkEmptyUndefined,CurrentUserName,profilePictureStorage, SiteLogo,SiteFavicon,userId,Url ,baseUrl,Redirectlogin ,Redirectdashboard,CommonNotify,TriggerLogout,validEmailRegex,OwnerName,CurrentYear,UrlSplitter,ModalPopupDelete,ProfilePictureOutput};