import React, { useState ,useEffect} from "react";
 import {
    Link 
} from "react-router-dom";

  import ReactPlayer from 'react-player'


 import Header from '../includes/Header';

 import Sidebar from "../includes/Sidebar";

  import Share from "../share/Share"

  import CommentsPopup from "../commentspopup/Commentspopup"

  import Video from "../video/Video"



  import Miniprofile from "../sidebarsection/Miniprofile"

  import Mininotification from "../sidebarsection/Mininotification"

  import Suggestion from "../sidebarsection/Suggestions"



 


   import Singlepost from "../pages/Singlepost"



import {Akusername , Socketiourl, showLoader,frontUrl,AkuserId, SiteLogoDark,SiteLogo,redirectfrontPage,Redirectdashboard,Redirectlogin,Url ,userProfileurl ,postDetailurl} from '../Frontapi.js';


 


 
const Dashboard = () => {
const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)


const [shareurl, setShareurl]= useState('')
const [Showsharepopup, setShowsharepopup] = useState(0);
const [Showcommentspopup, setShowcommentspopup] = useState(0);
const [Showvideopopup, setShowvideopopup] = useState(0);
const [videopostid, setvideopostid] = useState('');



const [viewpostid, setviewpostid] = useState('');



const [latitude, setLatitude] = useState('');
const [longitude, setLongitude] = useState('');



const [postsList, setpostsList] = useState([]);
const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage,setrecordsPerPage] = useState(10);
const [increrecordsPerPage,setincrerecordsPerPage] = useState(10);


const [nPages, setnPages] = useState(1);
const [pageNumbers,setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));


const [totalcount, settotalcount] = useState(0);

const [checkrecordsLength, setCheckrecordsLength] = useState('Loading...');


 

   
const GetPosts = async (value ) => {

 
 showLoader(true)
 

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)

  value = (typeof value !== 'undefined') ? value : currentPage;

  //   value = (typeof value !== 'undefined') ? value : recordsPerPage;


      var postdata={'currentpage':value, 'perpage':recordsPerPage ,'checkuserid':AkuserId  , active : 1 , latitude  :latitude, longitude:longitude, list:1 };

        const response = await fetch(Url+"getposts", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
        


var postdatavalues= data.data;

 if(postdatavalues.length == 0){

setCheckrecordsLength('No posts found ')
    
setpostsList([])
    }


 
 settotalcount(data.count)
 

 if(postsList.length == 0){
    setpostsList( postdatavalues)




 }else{
 

var newvalues = [...postsList,postdatavalues];
 


        var array1 = postsList.concat(postdatavalues);

 


  var array2 = [];
  var values = [];
  var value;

  for(var i = 0; i < array1.length; i++) {
    value = array1[i]['_id'];

    if(values.indexOf(value) === -1) {
      array2.push(array1[i]);
      values.push(value);
    }
  }
 

   setpostsList(array2)

 }


 
    
 

    
          } else{
                 
          }

           
       })


 
  };
/*

const UpdateLikes= async (e,value ,username) => {




  var liked = e.target.attributes.getNamedItem('data-update-like').value;

  if(parseInt(liked)  ===  parseInt(0) ){


   e.target.setAttribute('data-update-like',1);
      e.target.setAttribute('class','fa fa-heart');

     socket.on("joinuser",{token:username});
    socket.emit("newlike",{token:username, from:Akusername})




}else{
    e.target.setAttribute('data-update-like',0);
      e.target.setAttribute('class','fa fa-heart-o');

}


    var postdata={postid:value,'userid':AkuserId};

        const response = await fetch(Url+"update-likes", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

              } else{
                 
          }

           
       })



};




 
 

const UpdateSaved= async (e, value ) => {


  var liked = e.target.attributes.getNamedItem('data-update-save').value;
if(parseInt(liked)  ===  parseInt(0) ){
  e.target.setAttribute('data-update-save',1);
      e.target.setAttribute('class','fa fa-bookmark');


}else{
    e.target.setAttribute('data-update-save',0);
      e.target.setAttribute('class','fa fa-bookmark-o');

}


    var postdata={postid:value,userid:AkuserId};

        const response = await fetch(Url+"update-saves", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

              } else{
                 
          }

           
       })



};




const goToPost = (id) => {

     //window.location.href= frontUrl+'p/'+id;


    document.getElementsByClassName('post-mob-img-'+id)[0].style.display = "none";
     document.getElementsByClassName('post-video-'+id)[0].classList.add('display_mob_block')  ;

         //   document.getElementsByClassName('post-video-'+id)[0].requestFullscreen();
         //   document.getElementsByClassName('post-video-'+id)[0].webkitRequestFullscreen();
 
}


const showShareProfile= async(postid)=>{

setShareurl(postDetailurl + postid);
setShowsharepopup(1)



}




const openVideopopup= async(postid)=>{

  setvideopostid(postid)
 setShowvideopopup(1)



}






  */

 
const closeShareProfile= async()=>{

setShowsharepopup(0)



}

const closeVideopopup= async()=>{

setShowvideopopup(0)



}


const openCommentpopup= async(postid)=>{

setviewpostid(postid)
 setShowcommentspopup(1)



}

const closeCommentpopup= async()=>{

setShowcommentspopup(0)



}





const handleScroll = () => {


 if(parseInt(document.getElementsByClassName('post-main-section').length) != parseInt(totalcount)  ){ 

         if (Math.round( window.innerHeight + document.documentElement.scrollTop)  === document.documentElement.offsetHeight  ) {
               document.querySelectorAll("body")[0].classList.add('is_loading')

          // var NewrecordsPerPage=parseInt(recordsPerPage)+parseInt(increrecordsPerPage)
         //   setrecordsPerPage(NewrecordsPerPage)


       var NewCurrentpage=parseInt(currentPage)+parseInt(1)
            setCurrentPage(NewCurrentpage)


             GetPosts(NewCurrentpage);
             setTimeout(function(){
                 document.querySelectorAll("body")[0].classList.remove('is_loading')
             },2000)
                        

        }
}


 };

  const showPosition = async (position)  =>{

             setLatitude(position.coords.latitude)
              setLongitude(position.coords.longitude)
  GetPosts();

    }

    const ScrolleventDisableVideos= async ( )  =>{

 

var toggleimagesection = 0;
 
if(parseInt(document.documentElement.scrollTop - localStorage.getItem('lastheight')) >  parseInt(500)    ){
toggleimagesection=1;

}

if(parseInt(document.documentElement.scrollTop - localStorage.getItem('lastheight')) <   parseInt(0) &&parseInt(document.documentElement.scrollTop - localStorage.getItem('lastheight')) <   parseInt(-500)   ){
toggleimagesection=1;


}
 
 if(toggleimagesection == 1){


   // var mob_view_sections= document.getElementsByClassName('post-mob-img-section')
    var mob_video_sections= document.getElementsByClassName('post-video-section')

             
        //    Array.prototype.forEach.call(mob_view_sections, function(el) {

             //   el.style.display='block';
           // })
            Array.prototype.forEach.call(mob_video_sections, function(el) {
                  
                
                 var data_post_id=el.attributes.getNamedItem('data-post-id').value;

                 



                                      el.style.display='none';

  document.getElementById('plmb'+data_post_id).style.display='block';
  document.getElementById('plmb'+data_post_id).style.opacity=1;


                    var bkvideolink= el.innerHTML;
                  
                                      el.innerHTML='';
 el.innerHTML=bkvideolink;

var fadeTarget = document.getElementById('plmb'+data_post_id);
    var fadeEffect = setInterval(function () {
        if (!fadeTarget.style.opacity) {
            fadeTarget.style.opacity = 1;
        }
        if (fadeTarget.style.opacity > 0) {
            fadeTarget.style.opacity -= 0.1;
        } else {
            clearInterval(fadeEffect);
        }
    }, 700);



setTimeout(function(){
           document.getElementById('plmb'+data_post_id).style.display='none';

                                 el.style.display='block';
},1000)
                   


            })

 }

 
  
 
 

 


    }

  

    useEffect(() => {

 

if(localStorage.getItem('dashboardReloaded') == null ){
 localStorage.setItem('dashboardReloaded', 1)

     window.location.reload()
} 


navigator.geolocation.getCurrentPosition(showPosition)
   GetPosts();


 

/*
 window.addEventListener('scroll', ScrolleventDisableVideos);
window.addEventListener("scrollend", (event) => {
     localStorage.setItem( 'lastheight', document.documentElement.scrollTop)

});*/



    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);

 


    }, [recordsPerPage,totalcount,latitude,longitude]);

  return ( <div>
   <Header pageInfo="Dashboard" />
   <div>
      <div id={(Showsharepopup == 1 || Showcommentspopup == 1 ) ? 'overlay-hidden':'' }  className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
    
      <div className="col-md-2 col-lg-1 col-xl-2 col-sm-12 col-xs-12 ">
         <Sidebar  showBottomNavbar={BottomNavbarstatus}  showMobSidebar="1" />
      </div>

        {/*main section**/}
      <div className="col-md-10 col-lg-11 col-xl-8 col-sm-12 col-xs-9 full-section">
         <div className="page-content">
            <div  >

            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 row">

            <div className="col-md-6  col-lg-6 col-xl-7 col-sm-12 col-xs-12">


              {(postsList.length > 0) ? 

              <div>
               {/*single**/}
               {postsList.map((post) => (

                <Singlepost postid={post._id} />

                         
                ))}
                {/*single**/}



                </div>:   <div className="no-records"> {checkrecordsLength} </div>

                    }

                </div>

                <div className="col-md-6 col-lg-6 col-xl-5 col-sm-12 col-xs-12 mob-dash-sidebar">
                  
                 <Miniprofile />
                
                 
                 <Mininotification />
              
                 
                 <Suggestion />
                



                </div>


                </div>



    </div>
    </div>
    </div>
      {/*main section**/}

     



</div>
{(Showsharepopup == 1) ?  
<div className="share-modal-box">
   <div className="share-modal-bg">
      <div className="share-modal-close">
         <button  onClick={closeShareProfile} > X </button>
      </div>
      <Share shareurl={shareurl} />
   </div>
</div>
: '' }
{(Showvideopopup == 1) ?  
<div className="video-modal-box">
   <div className="video-modal-bg">
      <div className="video-modal-close">
         <button  onClick={closeVideopopup} > X </button>
      </div>
      <Video videopostid={videopostid} />
   </div>
</div>
: '' }
{(Showcommentspopup == 1) ?  
<div className="comment-modal-box">
   <div className="comment-modal-bg">
      <div className="comment-modal-close">
         <button  onClick={closeCommentpopup} > X </button>
      </div>
      <CommentsPopup viewpostid={viewpostid} />
   </div>
</div>
: '' }
</div>
</div>
 
  );
};
export default Dashboard;