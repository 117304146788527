import React, { useState } from "react";
 import { Link, useNavigate } from "react-router-dom";


 import {SiteLogo,AkuserId,Akusertype, frontUrl,Url,Redirectdashboard,validEmailRegex,CommonNotify,checkEmptyUndefined,Redirectdashboard_Terms} from '../Frontapi.js';
 import Header from '../includes/Header';
 import Footer from '../includes/Footer';

 
const Register = () => {

  Redirectdashboard_Terms(AkuserId,Akusertype);
 

//  const urlParams = new URLSearchParams(window.location.search);

// const myParam = urlParams.get('referral');

//    localStorage.setItem('AKreferralcode', myParam)


 
  return (


  <div>
   <Header pageInfo="Register" />
   <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xs-9 full-section">
         {(AkuserId ==  null || AkuserId == '' || typeof AkuserId =='undefined'  ) ? 
         <div className="page-content">
            <div className="login-container">
               <div className="login-section">
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 row mb-25 padding-0" >
                     <div className="register-login-title-sec padding-0" >
                        <h1 className="register-login-title  ">Academic Gyan</h1>
                     </div>
                     <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3" >
                        <img src={SiteLogo} />
                     </div>
                  </div>

                  <div className="register-login-inst-sec mb-25">
                  <p  className="register-login-inst">We support multi lingual education For eg. 
                     If you are teacher and comfortable with any
                     Indian local language and you want to create 
                     content in those languages you are welcome.
                  </p>
                  <p className="register-login-inst mb-25">
                     Similarly if you are student and you are looking for content in your language this is the right place. 
                  </p>
                  <p className="register-login-inst mb-50">
                     Our objective is to help each and every child in  the world. Language should not be the bearer in their studies. We welcome you all to our application.
                  </p>
                  </div>




                  <Link className="signup-button bg-yellow-amigo"  to={frontUrl+'login'} >
                  Login</Link>              
                  <p  className="register-login-inst-option"> OR</p>
                  <Link className="signup-button bg-green-amigo"  to={frontUrl+'teacher-register'} >
                  Register as Teacher / Counselor
                  </Link>              
                  <Link className="signup-button bg-blue-amigo"  to={frontUrl+'student-register'} >
                  Register as Student</Link>              
               </div>
            </div>
         </div>
         : ''}
         <Footer />
      </div>
   </div>
</div>

 
 
  );
};
export default Register;