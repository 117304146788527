import React, { useState , useEffect} from "react";
 import {
  Link 
} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';


import {
 EmailShareButton,
 FacebookShareButton,
  
 LinkedinShareButton,
  
 
  
 WhatsappShareButton
  
} from "react-share";
 

 
import { SetNotificationAfterName,GetThumbnailImage,postDetailurl,userProfileurl,votePosturl,frontUrl,Url,SiteLogo,SiteLogoDark,AkuserId,Akusername, Notifyalert, RemoveNotificationAfterName } from '../Frontapi.js';

 import {addReelIcon,userIcon ,challengeIcon,angleLeft ,challengewinnerIcon} from '../ImagesIcons.js';
 import Sidebar from "../includes/Sidebar";

   import Singlepost from "../pages/Singlepost"


 
import { DynamicImagesUrl } from '../GetDynamicImages.js';
 

 import ContestTopbar from "./Topbar";



 

 const Challenges = ( ) => {


const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)
/**Main page*/


const [challengesList, setchallengesList] = useState([]);
const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage,setrecordsPerPage] = useState(10);
const [checkrecordsLength,setcheckrecordsLength] = useState('Loading...')


  
 

const GetChallenges=async()=>{


 
  
 
   var postdata={   'currentpage':currentPage,'perpage':recordsPerPage,    'winners' : 1  };

    const response = await fetch(Url+"getcontests", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 
      if(data.data.length == 0){
  setcheckrecordsLength('No Challenges found')

  setchallengesList([]);
  return false
}

    setchallengesList(data.data );
 
    
     
     } else{
         
     }

      
    })


 




}
 
 

 
 

 useEffect(() => {
 GetChallenges()
 },[]);
 


return (<div>
  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
   <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
     <Sidebar showBottomNavbar={BottomNavbarstatus} />
   </div>
   <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 col-12 full-section">
     <div className="page-content">
      <div className="container-mobile">
         <div>
         <div className="page-container-section" >
           <h1 className="page-container-title cur-poi"  > 
            <span className="goto-section mob-view-back display_none" > <Link  to={-1}>  <img src={angleLeft} /> </Link>  </span> Challenges


 


           </h1>
         </div>

         <ContestTopbar active='winners' />
 
 

 <div>

         {(challengesList.length) > 0 ?
         <div className="challenge-wrapper">
           {challengesList.map((challenge) => (

          <div className="challenge-single">
   <div className=" col-12 col-md-12 col-sm-12 col-lg-12 challenge-single-title" >
      <div className=" col-12 col-md-12 col-sm-12 col-lg-12 row">
         <div className=" col-12 col-md-3 col-sm-3 col-lg-3 "> 
            <img className="img-responsive" src={challenge.profilepicture} />
         </div>
         <div className=" col-12 col-md-9 col-sm-9 col-lg-9 ">
         <span className=" col-12 col-md-12 col-sm-12 col-lg-12 row">{challenge.username}</span>   
            <span className=" col-12 col-md-12 col-sm-12 col-lg-12">{challenge.title}</span>   
            <span className=" col-12 col-md-12 col-sm-12 col-lg-12 display_flex">  
            <span className="  w-50-fl-lft">  
            Subject : {challenge.subjectname}
            </span> 
            <span className=" w-50-fl-lft">  
            Std : {challenge.standard}
            </span>
            </span>
        
         </div>
      </div>
   </div>
</div>


           ))}

         



         </div>
         : 
         <div className="no-records"> {checkrecordsLength} </div>
         }
        </div>
       

          </div>  
       
         
       
      </div>
     </div>
   </div>
  </div>
</div>

  )













 }
export default Challenges;