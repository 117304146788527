import React, { useState , useEffect} from "react";
 import {
  Link ,useNavigate
} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';


import {
 EmailShareButton,
 FacebookShareButton,
  
 LinkedinShareButton,
  
 
  
 WhatsappShareButton
  
} from "react-share";
 

 
import { challengeFees,UrlSplitter,SetNotificationAfterName,GetThumbnailImage,postDetailurl,userProfileurl,votePosturl,frontUrl,Url,SiteLogo,SiteLogoDark,AkuserId,Akusername, Notifyalert, RemoveNotificationAfterName } from '../Frontapi.js';

 import {addReelIcon,userIcon ,challengeIcon,angleLeft ,challengewinnerIcon} from '../ImagesIcons.js';
 import Sidebar from "../includes/Sidebar";

   import Singlepost from "../pages/Singlepost"


 
import { DynamicImagesUrl } from '../GetDynamicImages.js';
 

 


 

 const Details = ( ) => {

const challengeid = UrlSplitter(3);
  const navigate = useNavigate();


 
const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)
/**Main page*/


 const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage,setrecordsPerPage] = useState(10);
const [checkrecordsLength,setcheckrecordsLength] = useState('')


  const [challengetitle, setchallengetitle] = useState('');
  const [ challengeprize, setchallengeprize] = useState('');
  const [ challengewinner, setchallengewinner] = useState('');


  const [challengeattendees, setchallengeattendees] = useState([]);


  const [showContestDetailSection, setshowContestDetailSection] = useState(0);

  const [showAgreementSection, setshowAgreementSection] = useState(0);

  const [showWinnerSection, setshowWinnerSection] = useState(0);




  const [challengefees, setchallengefees] = useState(challengeFees);

  const [showsubmitButton, setshowsubmitButton] = useState(1);


  const [userpaidstatus, setUserpaidstatus] = useState(0);





const Paycontest=async()=>{


setshowsubmitButton(0)



  var postdata = {
                userid: AkuserId,
                challengeid: challengeid,
            
            }
            const response = await fetch(Url + "pay-contest", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        postdata
                    })
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        window.location.href = data.redirect
                       
                    } else {
                        if (typeof data.message != 'undefined') {
                            Notifyalert('error', data.message)
                        }
                    }
                })





}


const GetChallenge=async()=>{


 
  
 
   var postdata={    'id' :challengeid, 'checkuserid' : AkuserId  };

    const response = await fetch(Url+"getcontests", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 
    


        
        if(data.data.winner == 1){

            setshowWinnerSection(1)
            setshowContestDetailSection(0)
            setshowAgreementSection(0)

            setchallengewinner(data.data.winnername );

        }else{


             if(data.data.redirect == 1){
            // navigate("/contest")
        }

        setshowContestDetailSection(1)

    setchallengetitle(data.data.title );
    setchallengeprize(data.data.prize );
 
    setUserpaidstatus(data.data.userpaidstatus );
        }


       
     
     } else{
         
     }

      
    })


 




}
 


const GetChallengeattendees=async()=>{


 
  
 
   var postdata={    'currentpage':currentPage,'perpage':recordsPerPage,   'challengeid' :challengeid, 'checkuserid' : AkuserId  };

    const response = await fetch(Url+"getcontest-details-attendees", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 
    
 

setchallengeattendees(data.data)

         
     
     } else{
         
     }

      
    })


 




}



 const ProceedAgreement =async()=>{


   setshowContestDetailSection(0)

 setshowAgreementSection(1)

 }
 
 const showDetailSection =async()=>{

   setshowContestDetailSection(1)

 setshowAgreementSection(0)

}
 
 

 useEffect(() => {
 GetChallenge()
 GetChallengeattendees()
 },[]);
 


return (<div>
  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
   <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
     <Sidebar showBottomNavbar={BottomNavbarstatus} />
   </div>
   <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 col-12 full-section">
     <div className="page-content">
      <div className="container-mobile">
         <div>



         {(showWinnerSection == 1 ) ? 

           <div>


 <div className="page-container-section" >
           <h1 className="page-container-title cur-poi"  > 
            <span className="goto-section  " > <Link   to={-1} >  <img src={angleLeft} /> </Link>  </span> Contest Winner


 


           </h1>
         </div>




                <div className=" col-12 col-md-12 col-sm-12 col-lg-12">
           <p className="text-center winner-label">{challengewinner}</p>
           <div>  <img src={challengewinnerIcon} />  </div>

 
           { (challengewinner == Akusername) ?
           <p className="text-white ft-22">Our team will connect you</p> :''}


            </div>


    


    </div>  :''}
 
{(showAgreementSection == 1 ) ? 
  <div>
 <div className="page-container-section" >
           <h1 className="page-container-title cur-poi"  > 
            <span className="goto-section  " > <a  onClick={showDetailSection}  >  <img src={angleLeft} /> </a>  </span> Agreement


 


           </h1>
         </div>


   <div className="contest-agreement"> 

<p><span className="contest-highlight">I here to learn and clear my doubts and learn my 
subject. I will follow all rules and regulations of</span> <b className="stream-author"> 
Fststeps Pvt. Ltd.</b></p>

<h5>Rules and Regulations</h5>

<ol>
<li>
This is time base challenge </li>
<li> 
 Time will be noted based on response send to server</li>
<li>
 Make sure your phone network is strong </li>
<li>
 I not share any paid link to my friends or relatives</li>
<li>
Sessions will be between 30 min to 60 min.</li>
<li>
 Fstetps Pvt. Ltd., is only providing platform and they
are not responsible for my actions.
</li>
 </ol>
<h5>Payments</h5>
<ol>
<li>
 Payments are made for challenge and it is not 
refundable  </li>
<li> 
 I understand Fststeps Pvt. Ltd., is the aggregator and
These challenges are designed by teacher and not by
Fststeps Pvt. Ltd.</li>
</ol> 

<h5>Important Point to be considered</h5>
<ol>
<li>
 I will make sure my network signals are strong and
no disconnection caused from my side </li>
<li> 
 Any disconnection caused due to network is fully my
Responsibility </li>
<li> 
 I respect result shared by Fststeps Pvt. Ltd., as the
time calculation done based on response received by 
Server.
</li>
</ol> 


 {(showsubmitButton == 1) ? 

             <button onClick={Paycontest}  className=  "me-1 mb-1 contest-sec-btn agree-proc-btn bg-yellow-amigo"    >I agree</button>
: 
             <button    className={ "me-1 mb-1 contest-sec-btn agree-proc-btn bg-yellow-amigo  disabled-btn" }  >I agree</button>


}



</div> </div>  :''}


{(showContestDetailSection == 1 ) ? 


 <div  >

  <div className="page-container-section" >
           <h1 className="page-container-title cur-poi"  > 
            <span className="goto-section  " > <Link  to={-1}>  <img src={angleLeft} /> </Link>  </span> {challengetitle}


 


           </h1>
         </div>


<div className=" col-12 col-md-12 col-sm-12 col-lg-12 row text-center mb-30">

<h5 className="clr-white">{challengetitle} - Prize {challengeprize} /-</h5>



{(userpaidstatus == 1) ? 



<p className="highlight-clr">Once you clicked on participate, the question sections will be displayed.</p>

: 

<p className="highlight-clr">You will be charged Rs. {challengefees}/- to participate this challenge</p>

}



{(userpaidstatus == 1) ? 
<div className=" col-12 col-md-12 col-sm-12 col-lg-12  ">

   <Link  to={frontUrl+'contest/attend/'+challengeid}   className=  "me-1 mb-1 contest-sec-btn agree-proc-btn   bg-yellow-amigo"    >Participate</Link>

</div>
:     




<div className=" col-12 col-md-12 col-sm-12 col-lg-12  ">

   <button onClick={ProceedAgreement}  className=  "me-1 mb-1 contest-sec-btn agree-proc-btn  bg-yellow-amigo"    >Participate</button>

</div>
}





</div>  





 <div>

         {(challengeattendees.length) > 0 ?
         <div className="challenge-wrapper">
           {challengeattendees.map((challengeattendee) => (

          <div className="challenge-single">
   <div className=" col-12 col-md-12 col-sm-12 col-lg-12 challenge-single-title" >
      <div className=" col-12 col-md-12 col-sm-12 col-lg-12 row">
         <div className=" col-5 col-md-3 col-sm-3 col-lg-3 "> 
            <img className="img-responsive" src={challengeattendee.profilepicture} />
         </div>
         <div className=" col-7 col-md-9 col-sm-9 col-lg-9 padding-zero">
            <span className=" col-12 col-md-12 col-sm-12 col-lg-12 row mb-5" >Rank {challengeattendee.rank}</span>   
            <span  className=" col-12 col-md-12 col-sm-12 col-lg-12 row mb-5">{challengeattendee.username}</span>   
            <span  className=" col-12 col-md-12 col-sm-12 col-lg-12  row mb-5" ><small className="ft-12">Time taken :  {challengeattendee.timetaken}</small></span>   

            
          </div>
      </div>
   </div>
</div>


           ))}

         



         </div>
         : 
         <div className="no-records"> {checkrecordsLength} </div>
         }
        </div>
   </div>

        : '' }






       

          </div>  
       
         
       
      </div>
     </div>
   </div>
  </div>
</div>

  )













 }
export default Details;