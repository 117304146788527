import React, {useEffect, useState } from "react";
import  ReactDOM  from 'react-dom/client';

  import {UrlSplitter,convertTimeStamp,baseUrl,Url,ModalPopupDelete,UpdateNotify,Socketiourl, redirectPage,checkpermissionredirect} from './../../Api.js';

 import { Link } from 'react-router-dom';
 import io from 'socket.io-client';

 import {Socketcall} from '../../../socketcall/Socketcall.js';


 

import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";

import Questionsection from "./Questionsection";


//import Pagination from "../../includes/Pagination";

 
const Postlist = () => {

         const [id] = useState(UrlSplitter(3));
 
 
 

 const [questionsList, setquestionsList] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);


 const [notification, setnotification] = useState("");
 const [notificationtype, setnotificationtype] = useState("");
const [nPages, setnPages] = useState(1);
    const [pageNumbers,setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));

 //const nPages = Math.ceil(postsList.length / recordsPerPage)

    const [displaydata, setdisplaydata] = useState([]);

       const [questionscount, setquestionscount] = useState(0);



  const GetQuestions = async (value ) => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)

    value = (typeof value !== 'undefined') ? value : currentPage;

      var postdata={'currentpage':value,'perpage':recordsPerPage,admin:1 , id:id };

        const response = await fetch(Url+"getchallengesquestions", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) {

setquestionscount(data.data.length)
        setquestionsList(data.data );
       
         
          } else{
                 
          }

           
       })


 
  };
 
 

  
  const AddQuestion= async (  ) => {

 

//createRoot(document.getElementById("questions_extra")).render(<p>Hellos</p>)


 //document.getElementById("questions_extra").append(<Questionsection />);

 
 
 var d1 = document.getElementById('questions_extra');
d1.insertAdjacentHTML('beforeend', '      <div class="col-12 col-md-12 col-lg-12 col-xl-12 col-xs-12 row">            <div class="col-12 col-md-12 col-lg-12 col-xl-12 col-xs-12 mb-25" >             <label>Question </label>    <input type="hidden" name="questionid[]"   className="form-control"      />        <input type="text"  name="question[]" class="form-control"    type="text"  />            </div>  <div class="col-12 col-md-12 col-lg-12 col-xl-12 col-xs-12 row" >            <div class="col-12 col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-6" >        <label class="mb-10">Option 1 / Right Answer </label>              <input type="text" name="option1[]"   class="form-control"    type="text"  />             </div>             <div class="col-12 col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-6" >             <label class="mb-10">Option 2 </label>              <input type="text" name="option2[]"     class="form-control"    type="text"  />             </div>              <div class="col-12 col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-6" >             <label class="mb-10">Option 3 </label>              <input type="text"  name="option3[]"  class="form-control"    type="text"  />             </div>             <div class="col-12 col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-6" >             <label class="mb-10">Option 4 </label>              <input type="text" name="option4[]"   class="form-control"    type="text"  />   </div>      </div>  </div>   <hr/>   ');

// ReactDOM.render(<Questionsection />, document.getElementById('root'));

 
 
}


  const UpdateQuestion= async (  ) => {

      const form = document.querySelector('form');
            const data = new FormData(form);

 
 
            var questionskey=[]
             var questionidkey=[]
            var option1key=[]
            var option2key=[]
            var option3key=[]
             var option4key=[]

             data.forEach(function(value, key) {

                 if(key == 'question[]'){

                 var values = {'value' :value}

                  questionskey.push(value)
                }

                  if(key == 'questionid[]'){

                 var values = {'value' :value}

                  questionidkey.push(value)
                }

                

                if(key == 'option1[]'){

                 var values = {'value' :value}

                  option1key.push(value)
                }
                if(key == 'option2[]'){

                 var values = {'value' :value}

                  option2key.push(value)
                }
                if(key == 'option3[]'){

                 var values = {'value' :value}

                  option3key.push(value)
                }
                if(key == 'option4[]'){

                 var values = {'value' :value}

                  option4key.push(value)
                }


                 
             });

          //    var questionskey = Object.assign({}, questionskey);

 

    var postdata={  challengeid:id ,questions : questionskey , questionid : questionidkey , option1 :option1key , option2 : option2key , option3 : option3key , option4 :option4key  };

        const response = await fetch(Url+"updatechallengequestion", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
       
         setnotificationtype('success')
                    setnotification(data.message)
                      redirectPage('challenges')
          } else{
                 
          }

           
       })




}

 
 
  // const indexOfLastRecord = currentPage * recordsPerPage;
  //  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  //  const currentRecords = postsList.slice(indexOfFirstRecord, indexOfLastRecord);
  //  const nPages = Math.ceil(postsList.length / recordsPerPage)



//pagination part
     const setQuestionFieldValue = (e) => {


                    e.target.value = e.target.value

                }




          useEffect(() => {
   GetQuestions();


 


  }, []);
   
     
  //pagination part

  return (
      <div id="app">
            <Header title="Challenges Posts List" />

         <Sidebar activePage="Challenges" activeChildPage="Listchild" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage="Challenges Questions" />

         
              <section className="section">
        <div className="card">
            <div className="card-header">
               <span>Questions</span>
             </div>
            <div className="card-body">

 <form className="form form-horizontal"  >



 { (questionscount > 0) ?
 <div>

                       {questionsList.map((questionSingle) => (


<div>
<div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xs-12 row"> 


            <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xs-12 mb-25" >
             <label>Question </label>

            <input type="text"  name="question[]" onChange={(e)=>setQuestionFieldValue(e)}  className="form-control"   value={questionSingle.question}     />
              <input type="hidden" name="questionid[]"   className="form-control"  value={questionSingle._id}      />

            </div>

            <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xs-12 row" >

            <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-6" >
             <label className="mb-10">Option 1 / Right Answer </label>
              <input type="text" name="option1[]"   className="form-control"  value={questionSingle.option1}      />
             </div>


             <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-6" >
             <label className="mb-10">Option 2 </label>
              <input type="text" name="option2[]"     className="form-control"    value={questionSingle.option2}    />
             </div>


              <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-6" >
             <label className="mb-10">Option 3 </label>
              <input type="text"  name="option3[]"  className="form-control"    value={questionSingle.option3}    />
             </div>


             <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-6" >
             <label className="mb-10">Option 4 </label>
              <input type="text" name="option4[]"   className="form-control"   value={questionSingle.option4} />

             </div>



            </div>



            </div>

            <hr/>
             </div>



                          ))}


 

</div>

:
<div>
<div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xs-12 row"> 


            <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xs-12 mb-25" >
             <label>Question </label>

            <input type="text"  name="question[]" className="form-control"      />
              <input type="hidden" name="questionid[]"   className="form-control"      />

            </div>

            <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xs-12 row" >

            <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-6" >
             <label className="mb-10">Option 1 / Right Answer </label>
              <input type="text" name="option1[]"   className="form-control"      />
             </div>


             <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-6" >
             <label className="mb-10">Option 2 </label>
              <input type="text" name="option2[]"     className="form-control"     />
             </div>


              <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-6" >
             <label className="mb-10">Option 3 </label>
              <input type="text"  name="option3[]"  className="form-control"    />
             </div>


             <div className="col-12 col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-6" >
             <label className="mb-10">Option 4 </label>
              <input type="text" name="option4[]"   className="form-control"  />

             </div>



            </div>



            </div>

            <hr/>
             </div>
 }

            

 
            <a onClick={AddQuestion} className="btn btn-success"> Add question </a>

            <div id="questions_extra" className="mb-50"> </div>


            <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xs-12 row text-center" >

 <div  >

<a onClick={UpdateQuestion} className="btn btn-success"> Update</a>

</div>

</div>
            </form>
               
            </div>


               



        </div>
 
    </section>

                <Footer />
            </div>


         </div>

      </div>
 
 
  );
};
export default Postlist;