import React, { useState ,useEffect} from "react";
     import {
    Link,
    useNavigate
} from "react-router-dom";
 import Sidebar from "../includes/Sidebar";



import {postDetailurl,frontUrl,SiteLogo,AkuserId,Url  , SiteLogoDark,SetNotificationAfterName,RemoveNotificationAfterName ,Notifyalert,UrlSplitter} from '../Frontapi.js';



 


 
const Newpost = () => {
  const navigate = useNavigate();

const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)

const [orderlist, setOrderlist] = useState([]);
   
  const orderid = UrlSplitter(2);


const Checkorder =async(e) =>{

 
        
var postdata = { orderid : orderid }

         const response = await fetch(Url+"check-order-status", {
        method: "POST",
         headers: {
                    "Content-Type": "application/json",
                },
 body: JSON.stringify({
                    postdata
                }),         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

            

             Getorders();
             

          } else{
               
          }

           
       })
 
  

}


const Getorders =async(e) =>{

 
        
var postdata = { userid : AkuserId }

         const response = await fetch(Url+"get-orders", {
        method: "POST",
         headers: {
                    "Content-Type": "application/json",
                },
 body: JSON.stringify({
                    postdata
                }),         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

            var orderslist = data.data

 
            setOrderlist(orderslist)

             

          } else{
               
          }

           
       })
 
  

}

  useEffect(() => {

 Checkorder();
 Getorders();
 
 
   

    }, [ ]);


 
  return (

      <div>
       

     
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section"> 
          <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 "> 

               
<Sidebar showBottomNavbar={BottomNavbarstatus} />

           </div>
            <div className="col-md-9 col-lg-9 col-sm-12 col-xs-9 full-section  col-12"> 


              <div className="page-content">

                <div className="container-mb"> 
                <form id="NewPostForm" >
                 <div className="page-container-section"  >
                  <h1 className="page-container-title cur-poi"   > <span className="goto-section" >  </span> Orders</h1>
                </div>


                <div className="order-section-wrapper">

                {orderlist.map((ordersingle) => (
                <div className="order-section-single">


                 <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 row"> 
                                   <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3"> 
                                   <Link to={postDetailurl+ordersingle.postid } >  <div className="order-section-img">   <img src={ordersingle.thumbnail } /></div>
                                   </Link>
                                   </div>

                                     <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6"> 
                                       <div className="order-section-info">
                                       <p className="order-post-topic"> <Link to={postDetailurl+ordersingle.postid } > {ordersingle.topic } </Link>  </p>
 <p> {ordersingle.createddatetime } </p>
  </div>
                                     </div>

                                      <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3"> 
                                       <div className="order-section-status">
                                          <p> <span className={'order-status-'+ordersingle.paymentstatus.toLowerCase()  } > {ordersingle.paymentstatus }  </span>      </p>
   <p className="order-post-price"> {ordersingle.price } </p>
 </div>
                                      </div>

                 </div>

           
                </div>

                ))}

                </div>
              

                  

  
</form>

               </div>
               </div>



           </div>
              

     </div>
    
           

      </div>
 
 
  );
};
export default Newpost;