import React, {
    useState,
    useEffect
} from "react";
import {
    Link,
    useNavigate
} from "react-router-dom";
import Header from '../includes/Header';
import Sidebar from "../includes/Sidebar";
import {
    userIcon,
    streamingIcon,
    angleLeft
} from '../ImagesIcons.js';
import {
   checkUsertypeRedirectIfStudent,
    showLoader,
    SiteLogo,
    SiteLogoDark,
    AkuserId,
    Url,
    SetNotificationAfterName,
    RemoveNotificationAfterName,
    Notifyalert,
    UrlSplitter
} from '../Frontapi.js';
const Livestreams = () => {

 


        const [BottomNavbarstatus, setBottomNavbarstatus] = useState(1)
        const username = UrlSplitter(2);
        const [Userprofilepicture] = useState({
            'preview': SiteLogoDark
        });
        const [streamsList, setstreamsList] = useState([]);
        const [currentPage, setCurrentPage] = useState(1);
        const [recordsPerPage, setrecordsPerPage] = useState(10);
        const [activeLivestream, setactiveLivestream] = useState(1);
        const [activeUpcomingstream, setactiveUpcomingstream] = useState(0);
        const [checkrecordsLength, setcheckrecordsLength] = useState('Loading...')
        const showFollowers = () => {}
        const showFollowings = () => {}
        const GetUpcomingStreams = async (value) => {
            setcheckrecordsLength('Loading...')
            setactiveLivestream(0)
            setactiveUpcomingstream(1)
            showLoader(true);
            setstreamsList([])
            value = (typeof value !== 'undefined') ? value : recordsPerPage;
            var postdata = {
                'upcoming': 1,
                'currentpage': 1,
                'perpage': recordsPerPage,
                'checkuserid': AkuserId
            };
            const response = await fetch(Url + "getstreams", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        var postdatavalues = data.data;
                        if (parseInt(data.count) == parseInt(0)) {
                            setcheckrecordsLength('No Streams found')
                        }
                        setstreamsList(postdatavalues);
                    } else {}
                })
        }
        const checkStream = async (value) => {
            var postdata = {
                'currentpage': 1,
                'perpage': recordsPerPage,
                'checkuserid': AkuserId
            };
            const response = await fetch(Url + "check-stream", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        GetStreams();
                    } else {
                        GetStreams();
                    }
                })
        };
        const GetStreams = async (value) => {
            setcheckrecordsLength('Loading...')
            setactiveLivestream(1)
            setactiveUpcomingstream(0)
            showLoader(true);
            setstreamsList([])
            // let formData = new FormData()
            //  formData.append('currentpage', currentPage)
            //   formData.append('perpage', recordsPerPage)
            value = (typeof value !== 'undefined') ? value : recordsPerPage;
            var postdata = {
                'currentpage': 1,
                'perpage': recordsPerPage,
                'checkuserid': AkuserId
            };
            const response = await fetch(Url + "getstreams", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        var postdatavalues = data.data;
                        if (parseInt(data.count) == parseInt(0)) {
                            setcheckrecordsLength('No Streams found')
                        }
                        setstreamsList(postdatavalues);
                    } else {}
                })
        };




        const checkUsertypeRedirect = async (value) => {


         await checkUsertypeRedirectIfStudent()

           
        };






        useEffect(() => {

            checkUsertypeRedirect();

            checkStream();
        }, [recordsPerPage]);
 
  return (
<div>

   <Header pageInfo="Streams" />


   <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
      <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
         <Sidebar showBottomNavbar={BottomNavbarstatus} />
      </div>
      <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 col-12 full-section">
         <div className="page-content">
            <div className="container-mobile">
               <div className="page-container-section"  >
                  <h1 className="page-container-title cur-poi"   > 
                    <span className="goto-section  mob-view-back display_none" > <Link  to={-1}>  <img src={angleLeft} /> </Link>   </span> Streaming
                     <span className="live-streaming-title" style={{float:'right'}}>  <img className="streaming-icon" src={streamingIcon} /> </span>
                  </h1>
               </div>
               <div className="live-info-tabs" >
                  <ul>
                     <li onClick={GetStreams}  className="info-text" > <a className={activeLivestream == 1 ? 'active':''}>  Today</a> </li>
                     <li > <a className="pipeline"> &nbsp; </a> </li>
                     <li onClick={GetUpcomingStreams} className="info-text"> <a className={activeUpcomingstream == 1 ? 'active':''}> Upcoming </a></li>

                      <li > <a className="pipeline"> &nbsp; </a> </li>

                      <li onClick={GetUpcomingStreams} > <Link  to='/my-streams' > My </Link></li>



 
                  </ul>
               </div>
               {(streamsList.length) > 0 ?

               <div className="stream-posts">


                {streamsList.map((stream) => (
                  <div className="stream-post-single">
   <div className="stream-post-user-info col-12"  >
      <span className="stream-post-user-name col-6">
         <div className="stream-post-user-img-sec"> <img src={stream.userprofilepicture} />   <span>{stream.username}</span> </div>
      </span>
      <span className="stream-post-add-info col-6 text-right">
         <div className="stream-post-user-img-sec"> Std : {stream.standard}   <br/> <span className="stream-info-datetime"> Starts at : <small> {stream.startdatetime}  </small> </span></div>
      </span>
      {/*  <span className="stream-post-user-type col-6"> {stream.usertype}<br/> <span className="stream-info-datetime"> Starts at : <small> {stream.startdatetime}  </small> </span> </span>*/}
   </div>
   <div className="stream-post-info col-12"  >
      <span className="stream-post-user-name col-6"> Subject :  {stream.subjectname}  </span>
      <span className="stream-post-add-info col-6 text-right"> Topic :  {stream.topic}  </span>
   </div>
   <div className="stream-post-info col-12"  >
      {stream.caption}  
   </div>
</div>
                 ))}

               </div> :  <div className="no-records"> {checkrecordsLength} </div>  }



            </div>
         </div>
      </div>
   </div>
</div>
  );
};
export default Livestreams;