import React, { useState , useEffect} from "react";
 import {
  Link ,useNavigate
} from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';


import {CopyToClipboard} from 'react-copy-to-clipboard';


import {
 EmailShareButton,
 FacebookShareButton,
  
 LinkedinShareButton,
  
 
  
 WhatsappShareButton
  
} from "react-share";
 

 
import { UrlSplitter,SetNotificationAfterName,GetThumbnailImage,postDetailurl,userProfileurl,votePosturl,frontUrl,Url,SiteLogo,SiteLogoDark,AkuserId,Akusername, Notifyalert, RemoveNotificationAfterName } from '../Frontapi.js';

 import {ChallengeQuestionImg,addReelIcon,userIcon ,challengeIcon,angleLeft ,challengewinnerIcon} from '../ImagesIcons.js';
 import Sidebar from "../includes/Sidebar";

   import Singlepost from "../pages/Singlepost"


 
import { DynamicImagesUrl } from '../GetDynamicImages.js';
 

 


 

 const Details = ( ) => {

const challengeid = UrlSplitter(3);
  const navigate = useNavigate();


 
const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)
/**Main page*/


const [challengesList, setchallengesList] = useState([]);
const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage,setrecordsPerPage] = useState(10);
const [checkrecordsLength,setcheckrecordsLength] = useState('')


  const [challengetitle, setchallengetitle] = useState('');
   const [challengeendtimer, setchallengeendtimer] = useState('');



    const [ challengenotstartedsection, setchallengenotstartedsection] = useState(0);


    const [challengesection, setchallengesection] = useState(0);



   const [completedchallengesection, setcompletedchallengesection] = useState(0);

   const [skippedquestioncontent, setSkippedquestioncontent] = useState(0);




   const [nextorder, setNextorder] = useState(0);
   const [initialSlider, setinitialSlider] = useState(1);



    const [challengequestion, setChallengequestion] = useState('');
      const [challengequestionid, setChallengequestionid] = useState([]);
    const [challengeoptions, setChallengeoptions] = useState([]);
    const [challengeslides, setChallengeslides] = useState([]);





    const [allquestionsattended, setAllquestionsattended] = useState(0);



 
  const [showsubmitButton, setshowsubmitButton] = useState(1);


    const [showSlider, setshowSlider] = useState(1);


 



const startChallenge=async()=>{

    var postdata={    'id' :challengeid, 'checkuserid' : AkuserId  };

    const response = await fetch(Url+"startcontest", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 
 
    
     
     } else{
         
     }

      
    })
 


}

const completeChallenge=async()=>{

    var postdata={    'id' :challengeid, 'checkuserid' : AkuserId  };

    const response = await fetch(Url+"completecontest", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 
 
    setchallengesection(0)
            setcompletedchallengesection(0)
            setAllquestionsattended(1)

     
     } else{
         
     }

      
    })
 


}



const GetChallenge=async()=>{

    var postdata={    'id' :challengeid, 'checkuserid' : AkuserId  };

    const response = await fetch(Url+"getcontests", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 


        
        
        if(data.data.userpaidstatus == 0){
            navigate("/contest")
        }

 
    setchallengetitle(data.data.title );
    setchallengeendtimer(data.data.endtimer )
      startTimer();

    checkContestStarted()
     
     } else{
         
     }

      
    })
 


}


const checkContestStarted=async()=>{

    var postdata={    'id' :challengeid, 'checkuserid' : AkuserId  };

    const response = await fetch(Url+"checkconteststartedcompleted", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 
 
        
        if(data.data.started == 1){
             checkAllanswered()
        }
         if(data.data.notstarted == 1){
             setchallengenotstartedsection(1)
         }

         if(data.data.completed == 1){
             setcompletedchallengesection(1)
         }



     }  

      
    })
 


}



const checkAllanswered=async()=>{

    var postdata={    'id' :challengeid, 'checkuserid' : AkuserId  };

    const response = await fetch(Url+"checkcontestquestionsallattended", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 
 
     
     setAllquestionsattended(1)
     } else{

        setchallengesection(1)
        GetChallengeQuestion()
        startChallenge()
         
     }

      
    })
 


}



const GetChallengeQuestion=async()=>{


setshowSlider(0)
 
   var postdata={    'id' :challengeid, 'checkuserid' : AkuserId,'order':nextorder  };

    const response = await fetch(Url+"getcontestsquestions", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 


     

        if(data.data.completed == 1){


            setchallengesection(0)
            setcompletedchallengesection(1)

            if(data.data.skipped > 0){
                setSkippedquestioncontent(1)
            }
            




        }else{

                setChallengequestionid(data.data.questionid );
                setChallengequestion(data.data.question );
                setChallengeoptions(data.data.options );
                setNextorder(data.data.order)
               
 setinitialSlider(data.data.order)
                setshowSlider(1)
                GetQuestionSlider(data.data.questionid)

        }


  
        
    
     
     } else{
         
     }

      
    })

}
const GetChallengeQuestionById=async(questionid)=>{



   var postdata={    'id' :challengeid, 'checkuserid' : AkuserId,'questionid':questionid  };

    const response = await fetch(Url+"getcontestsquestions", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 

            setChallengequestionid(data.data.questionid );
            setChallengequestion(data.data.question );
            setChallengeoptions(data.data.options );



            setchallengesection(1)
            setcompletedchallengesection(0)


     } else{
         
     }

      
    })

}


const GetQuestionSlider=async(challengequestionid)=>{

 var postdata={    'id' :challengeid,  'questionid' : challengequestionid,   'checkuserid' : AkuserId  };


  const response = await fetch(Url+"getcontestsquestionslides", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 
  
 
      setChallengeslides(data.data );

 

     
     
     } else{
         
     }

      
    })



}





const attendAnswer=async(e,challengekey)=>{



 var postdata={    'id' :challengeid,  'questionid' : challengequestionid, 'answer':challengekey ,  'checkuserid' : AkuserId  };

    const response = await fetch(Url+"attendcontestsquestions", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 
        

             // if (data.data.attendedall) { 

             //          setchallengesection(0)
             //        setcompletedchallengesection(1)


             //        }else{

                              GetChallengeQuestion()

                    //}

  
    
     
     } else{
         
     }

      
    })

 
}

const skipQuestion=async( )=>{


 var postdata={    'id' :challengeid,  'questionid' : challengequestionid,    'checkuserid' : AkuserId  };

    const response = await fetch(Url+"skipcontestsquestions", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 
   
  
      GetChallengeQuestion()
    
     
     } else{
         
     }

      
    })
      

}


 const [time, setTime] = useState(0);


 // const [minutes, setminutes] = useState('');
 // const [seconds, setseconds] = useState('');
 // const [milliseconds, setmilliseconds] = useState('');



  // const hours = Math.floor(time / 360000);

  // // Minutes calculation
  // const minutes = Math.floor((time % 360000) / 6000);

  // // Seconds calculation
  // const seconds = Math.floor((time % 6000) / 100);

  // // Milliseconds calculation
  // const milliseconds = time % 100;


 const startTimer=async()=>{
 

    // Run myfunc every second
    var myfunc = setInterval(function() {


if(challengeendtimer != ''){ 
    console.log(challengeendtimer)


   var countDownDate = new Date(challengeendtimer).getTime();

     var now = new Date().getTime();
    var timeleft = countDownDate - now;

    // Calculating the days, hours, minutes and seconds left
   // var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
  //  var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

       // var milliseconds = Math.floor((timeleft  % 100));


         
    // Result is output to the specific element
    //document.getElementById("days").innerHTML = days + "d "
 //   document.getElementById("hours").innerHTML = hours + "h " 
  //  document.getElementById("min").innerHTML = minutes + "m " 
  //  document.getElementById("sec").innerHTML = seconds + "s " 
      //  document.getElementById("msec").innerHTML = milliseconds + "s " 

    
        var myEle = document.getElementById("timercount");

        if(myEle) {
                document.getElementById("timercount").innerHTML = "Time Left : "+ minutes + "m : " + seconds + "s "  

        }


    // Display the message when countdown is over
    if (timeleft < 0) {
        clearInterval(myfunc);
     //   document.getElementById("days").innerHTML = ""
     //   document.getElementById("hours").innerHTML = "" 
        //document.getElementById("min").innerHTML = ""
      //  document.getElementById("sec").innerHTML = ""
       //   document.getElementById("msec").innerHTML = ""
      //  document.getElementById("end").innerHTML = "TIME UP!!";
       // window.location.reload()


         setchallengesection(0)
            setcompletedchallengesection(0)
            setAllquestionsattended(1)
    }

}

    }, 1000);



 }


 

 

 useEffect(() => {







 GetChallenge()


 




   /*let interval = null;

   interval = setInterval(() => {
   setTime((time) => time + 10);
   }, 100);*/

const unloadCallback = (event) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
  };


window.addEventListener("beforeunload", unloadCallback);
  return () => window.removeEventListener("beforeunload", unloadCallback);

 
 },[initialSlider,challengeendtimer]);


 


return (
  <div>
   <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
      <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
         <Sidebar showBottomNavbar={BottomNavbarstatus} />
      </div>
      <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 col-12 full-section">
         <div className="page-content">
            <div className="container-mobile">
               <div>



                  <div className="page-container-section" >
                     <h1 className="page-container-title cur-poi"  >
                        <span className="goto-section  " >
                           <Link  to={-1}>
                           <img src={angleLeft} /> </Link>  
                        </span>
                        {challengetitle}
                     </h1>
                  </div>



                  {(challengenotstartedsection == 1) ? 

                                    <p className="contest-start-warning">The challenge has not started. </p>
                                    : ''
              }


                  {(allquestionsattended == 1) ?
                  <div> 

                  <p className="complete-contest-success">You have completed the contest. You can check your results on challenges > attended </p>

                   <Link className="contest-answer-option bg-yellow-amigo clr-white" to={frontUrl + 'contest/results/'+challengeid }   > View Results </Link>

                  </div>


                  : 
                  <div> 


                  <div className="question-sliders">

                  

                  <Swiper
        slidesPerView={12}
         spaceBetween={10}
        grabCursor={true}

        initialSlide={initialSlider}


        className="mySwiper"
      >
      {challengeslides.map((challengeslide) =>( 


        <SwiperSlide   > 

        {(challengeslide.type == 'skipped') ? 
         <div className={challengeslide.type + '-slide  q-slide'}  onClick={(questionid)=>GetChallengeQuestionById(challengeslide.questionid)  }> </div> 

         :
        <div className={challengeslide.type + '-slide  q-slide'}> </div>
        } 



        </SwiperSlide>
         
           ))}
      </Swiper>  
      </div>  




     

        




         {(completedchallengesection == 1) ? 


         <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">

            {(skippedquestioncontent == 1) ? <p>You have some skipped questions. You can answer by clicking on the slide or complete the contest. </p>  : ''     }

                <p className="contest-answer-option bg-yellow-amigo clr-white" onClick={completeChallenge }   > Complete </p>
        </div>
                :''
               





                  }


                   {(challengesection == 1) ?   <div>


                  {/*   <div id="time">
                       <span class="digit" id="min">  {minutes.toString().padStart(2, "0")}</span>
            <span className="txt"> : </span>
            <span class="digit" id="sec">
                {seconds.toString().padStart(2, "0")}</span>
            <span className="txt"> : </span>
            <span className="digit" id="msec">
              {milliseconds.toString().padStart(2, "0")}</span>
        </div>*/ }


                     <div id="timer" className="text-white">
                       <span id="timercount" >  </span>
                    </div>


                  <p className="contest-question-section">
                     <img className="img-responsive" src={ChallengeQuestionImg} />
                     <span className="contest-question-section-content">{challengequestion}</span>
                  </p>

                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                     {challengeoptions.map((challengeoption) => (
                     <p className="contest-answer-option" onClick={ (e,challengekey)=> attendAnswer(e,challengeoption.key)} > {challengeoption.value} </p>
                     ))}
                    
                  </div>



                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">


                        <p className="contest-answer-select bg-yellow-amigo clr-white" onClick={skipQuestion }   > Skip </p>


                     </div>


                  </div> : '' }


                  






                  </div>





                  }




                   </div>






            </div>
         </div>
      </div>
   </div>
</div>
  )













 }
export default Details;