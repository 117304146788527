import React, {useEffect, useState } from "react";
  import {UrlSplitter,convertTimeStamp,baseUrl,Url,ModalPopupDelete,UpdateNotify,Socketiourl,checkpermissionredirect} from './../../Api.js';

 import { Link } from 'react-router-dom';
 import io from 'socket.io-client';

 import {Socketcall} from '../../../socketcall/Socketcall.js';



  import {    
   Allowposts_view ,
   Allowposts_edit
 } from './../../Permissions.js';


import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";

//import Pagination from "../../includes/Pagination";

 
const Attendees = () => {

         const [id] = useState(UrlSplitter(3));

          const [userid] = useState(UrlSplitter(4));
 
 
  const [challengetitle, setchallengetitle] = useState('');
  const [ challengeresults, setchallengeresults] = useState([]);



 
 
 



 
const GetChallengeresults=async()=>{


 
  
 
   var postdata={    'id' :id, 'checkuserid' : userid , admin : 1 };

    const response = await fetch(Url+"getcontestsresults", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 
    
 

 
    setchallengetitle(data.data.title );
      setchallengeresults(data.data.results );

      
     } else{
         
     }

      
    })


 




}


          useEffect(() => {
   GetChallengeresults();


         checkpermissionredirect(Allowposts_view)



  }, []);
   
     
  //pagination part

  return (
      <div id="app">
            <Header title="Attendees List" />

         <Sidebar activePage="Attendees" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage="Attendees"/>

{ModalPopupDelete()}
        
              <section className="section">
        <div className="card">
            <div className="card-header">
               <span>{challengetitle}  </span>
             </div>
            <div className="card-body">

               



               {challengeresults.map((challengeresult) => (

    <div className=" col-12 col-md-12 col-sm-12 col-lg-12  " >
      <div className=" col-12 col-md-12 col-sm-12 col-lg-12 row">
         <div className=" col-12 col-md-12 col-sm-12 col-lg-12 text-left  "> 
              <h4 className="  mb-10">  {challengeresult.question} </h4>

                <p>  Answer :  {challengeresult.answer}</p>

                <p className={'contest-result-'+challengeresult.result}  > Your Answer : {(challengeresult.useranswer == '' ) ?  'Skipped / Not Answered'  : challengeresult.useranswer }</p>

                <hr/>
         </div>
         
      </div>
   </div>
 


           ))}


            </div>
        </div>
 

    </section>

                <Footer />
            </div>


         </div>

      </div>
 
 
  );
};
export default Attendees;