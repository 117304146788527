import React, { useState ,useEffect} from "react";
     import {
    Link,
    useNavigate
} from "react-router-dom";
 import Sidebar from "../includes/Sidebar";



import {UrlSplitter,SiteLogo,AkuserId,Url  ,SetNotificationAfterName,RemoveNotificationAfterName ,Notifyalert} from '../Frontapi.js';



  import {angleLeft  } from '../ImagesIcons.js';

 import { Getpostimage,Getuserimage } from '../GetDynamicImages.js';


 
const Editpost = () => {

const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)

const [topic, setTopic] = useState("");
const [caption, setCaption] = useState("");
const [youtubeurllink, setYoutubeurllink] = useState("");
 const [thumbnail, setThumbnail] = useState({ preview: '', data: '' })

  const postid = UrlSplitter(2);

const Getpostdetails =async() =>{

 



 var postdata={_id:postid, checkuserid:AkuserId}

         const response = await fetch(Url+"get-post", {
        method: "POST",
         headers: {
          "Content-Type": "application/json" 
        } ,
        body:JSON.stringify({postdata}) 
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

            var postdata= data.data;

                       (async function() { 

   setThumbnail({preview:  postdata.thumbnail   })

                        })()
 
              

    setTopic(postdata.topic)
                    setCaption(postdata.caption)

                    setYoutubeurllink(postdata.youtubeurllink)

                  // setUsername(postdata.username)
            


                  // setpostLiked(postdata.liked)
                  // setpostSaved(postdata.saved)
                  // setpostLikescount(postdata.likescount)
                  // setppostTimediff(postdata.posttimediff)
                  // setcomments(postdata.comments.data)
             //Notifyalert('success', data.message)
            // document.getElementById("NewPostForm").reset()
                             
              

          } else{
               
          }

           
       })
 
  

}



const Updatepost =async(e) =>{

  e.preventDefault();



  /***Check Required***/
            var required_fields= document.getElementsByClassName('MustEnterField')
             if(document.getElementsByClassName('notify-input-text')[0]){
                document.getElementsByClassName('notify-input-text')[0].remove();
             } 
            var error=0;
            Array.prototype.forEach.call(required_fields, function(el) {
                        el.classList.remove('notify-input-missing');
                         el.classList.add('mb-10');
                        if(el.value === '' && error == 0){
                        var inputname =  el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                         el.classList.remove('mb-10');
                        error=1;

                       

                        SetNotificationAfterName(el.name, inputname + ' required')
             }
             });
              if(error === 1){
                    return false
            }
             /***Check Required***/



    let formData = new FormData()
      formData.append('thumbnail', thumbnail.data)
      formData.append('userid',  AkuserId)
      formData.append('topic',  topic)
      formData.append('caption',  caption)
      formData.append('youtubeurllink',  youtubeurllink)

      formData.append('postid',  postid)
      


         const response = await fetch(Url+"update-post", {
        method: "POST",
        body:formData 
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

              Notifyalert('success', data.message)
            // document.getElementById("NewPostForm").reset()
                             
              

          } else{
               
          }

           
       })
 
  

}


   const handleUserInput = (e) => {
    RemoveNotificationAfterName();
    var name = e.target.name;
    var value = e.target.value;
    switch (name) {
        case 'topic':
            setTopic(value);
             break;
        case 'youtubeurllink':
            setYoutubeurllink(value);
            setAutothumbnail(value)
             break;
        case 'caption':
            setCaption(value);
             break;          
         
      }
    }



 const handleThumbnailChange = (e) => {
      RemoveNotificationAfterName();

    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    }
     setThumbnail(img)
  }

    const setAutothumbnail = (value) => {




var youtubeviid = value.replace('https://www.youtube.com/watch?v=','')
youtubeviid = youtubeviid.split('&');
 
 const img = {
      preview:'https://img.youtube.com/vi/'+youtubeviid[0]+'/0.jpg'  ,
      data: '',
    }
     setThumbnail(img)

  }

  


   useEffect(() => {
    Getpostdetails();

  
    },[] );


  return (

      <div>
       

     
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section"> 
          <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 "> 

               
<Sidebar showBottomNavbar={BottomNavbarstatus} />

           </div>
            <div className="col-md-9 col-lg-9 col-sm-12 col-xs-9 full-section  col-12"> 


              <div className="page-content">

                <div className="container-mb"> 
                <form id="NewPostForm" >
                 <div className="page-container-section"  >
                  <h1 className="page-container-title cur-poi"   > <span className="goto-section" ><Link  to='/my-profile' >  <img src={angleLeft} /> </Link> </span> Edit Post</h1>
                </div>


                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12   post-input-sec"> 

                    <input onChange={(e) => handleUserInput(e)} value={youtubeurllink}  type="text" name="youtubeurllink" className="new-post-input MustEnterField mb-10" placeholder="Paste YouTube URL link of your content" />
                     </div>



 <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12     post-input-sec post-input-file-sec"> 

                     <div className="button-wrap">
        <label className="button-upload" for="upload">Attach Thumbnail</label>
        <input id="upload" type="file"  name="thumbnail"   onChange={handleThumbnailChange} />
          <br/>
                                    <br/>
                     {(thumbnail.preview != '') ? <img alt="thumbnail" className="img-responsive w-250px"  src={thumbnail.preview} />:''}

      </div>

 </div>


                     <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12   post-input-sec"> 
                    <input onChange={(e) => handleUserInput(e)} type="text" value={topic} name="topic" className="new-post-input MustEnterField mb-10" placeholder="Topic" />
                    </div>

                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12     post-input-sec"> 
                    <textarea onChange={(e) => handleUserInput(e)} value={caption} name="caption" placeholder="Write Caption" rows="10" className="new-post-textarea MustEnterField mb-10"></textarea>
                    </div>

                   
                    


                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12  post-input-sec"> 

                      <button type="submit" className=" me-1 mb-1 new-post-main-btn"  onClick={Updatepost}   >Update</button>
                   
                      
                    </div>
</form>

               </div>
               </div>



           </div>
              

     </div>
    
           

      </div>
 
 
  );
};
export default Editpost;