
import React, { useState, useEffect } from "react";
 
import {
     
    useNavigate
} from "react-router-dom";

import {frontUrl,TriggerLogout,Url,AkuserId,Akusertype,Redirectdashboard,RedirectDashboard_Login} from '../Frontapi.js';

import {addReelIcon} from '../ImagesIcons.js';


import Header from '../includes/Header';
 
  import Share from "../share/Share"

 
const Termsagreementstudent = () => {
        const navigate = useNavigate()


const [shareurl, setShareurl]= useState(frontUrl)
const [Showsharepopup, setShowsharepopup] = useState(0);


 

const [postContentDialog, setpostContentDialog] = useState(1)
const [adultpostcontentDialog, setadultpostcontentDialog] = useState(0)
const [afterpostContentDialog, setafterpostContentDialog] = useState(0)
const [kidsContentDialog, setkidsContentDialog] = useState(0)
const [advContentDialog, setadvContentDialog] = useState(0)
const [inviteContentDialog, setinviteContentDialog] = useState(0)

const [acceptTermsError, setacceptTermsError] = useState(1)
const [showAcceptpopup, setshowAcceptpopup] = useState(0)


const getAgreementsupdated = async() => {

    var postdata = {
        userid: AkuserId
    };

     const response = await fetch(Url + "getuseragreement", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    postdata
                }),
            }).then((response) => response.json())
            .then((data) => {
                if (data.success && data.data !== null) {
                    var userdata = data.data;

                    setpostContentDialog(userdata.postcontent);
                    setadultpostcontentDialog(userdata.adultpostcontent);
                    setafterpostContentDialog(userdata.afterpostcontent);
                    setkidsContentDialog(userdata.kidscontent);
                    setadvContentDialog(userdata.advcontent);
                    setinviteContentDialog(userdata.invitecontent);

                    
                    checkSetAgreementsview(userdata.postcontent,userdata.adultpostcontent,userdata.afterpostcontent,userdata.kidscontent,userdata.advcontent,userdata.invitecontent);
                  
                     
                } else {
                    
                }
            })

              
}

const SetAllClose=async()=>{
    setpostContentDialog(0);
    
    setadultpostcontentDialog(0);
    setafterpostContentDialog(0);
    setkidsContentDialog(0);
    setadvContentDialog(0);
    setinviteContentDialog(0);
}

const checkSetAgreementsview = async(postcontent,adultpostcontent,afterpostcontent,kidscontent,advcontent,invitecontent) => {

        if(postcontent == 1){
        SetAllClose(); 
        setadultpostcontentDialog(1);
        }  else{
            SetAllClose(); 
            setpostContentDialog(1);
            return false;
        }


        if(adultpostcontent == 1){
        setadultpostcontentDialog(0);
        setafterpostContentDialog(1);

        } else{
            SetAllClose(); 
            setadultpostcontentDialog(1);
            return false;
        }

        if(afterpostcontent == 1){
            setafterpostContentDialog(0);
            setkidsContentDialog(1);
        } else{
            SetAllClose(); 
            setafterpostContentDialog(1);
            return false;
        }

        if(kidscontent == 1){
        setkidsContentDialog(0);
        setadvContentDialog(1);
        } else{
            SetAllClose(); 
            setkidsContentDialog(1);
            return false;
        }


        if(advcontent == 1){
        setadvContentDialog(0);
        setinviteContentDialog(1);
          setacceptTermsError(0);
        } else{
            SetAllClose(); 
            setadvContentDialog(1);
            return false;
        }

        if(invitecontent == 1){
              setacceptTermsError(0);
        setinviteContentDialog(0);
    //    Redirectdashboard();
        } 

 

}

const ContinueTermsConditions = async()=>{

setshowAcceptpopup(0)   

}
const ExitTermsConditions = async()=>{
    
 TriggerLogout();   
RedirectDashboard_Login();
}


const UpdateagreementDialog = async(param,disagree='', noredirect='') => {


 
if(disagree == 'disagree'){ 

 setshowAcceptpopup(1)   
//TriggerLogout();
//RedirectDashboard_Login();
 
return false;

}

    var postdata = {
        userid: AkuserId,
        param:param
    };
if(disagree != 'disagree'){ 

  const response = await fetch(Url + "updateuseragreement", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    postdata
                }),
            }).then((response) => response.json())
            .then((data) => {
                if (data.success) {
                         if(Akusertype == 2 &&  data.count == 6){
                                                localStorage.setItem('AKcountsupdated','updated')

                    }
                     
                } else {
                    
                }
            })
}

  if(param == 'postcontent'){
     setpostContentDialog(0);
     setadultpostcontentDialog(1);
  }
  if(param == 'adultpostcontent'){
     setadultpostcontentDialog(0);
     setafterpostContentDialog(1);
  }
   if(param == 'afterpostcontent'){
     setafterpostContentDialog(0);
     setkidsContentDialog(1);
  }
    if(param == 'kidscontent'){
      setkidsContentDialog(0);
     setadvContentDialog(1);
  }
   if(param == 'advcontent'){
     setadvContentDialog(0);
     setinviteContentDialog(1);
     setacceptTermsError(0);

  }
     if(param == 'invitecontent'){
     setinviteContentDialog(0);

   
  }

  if(param == 'invitecontent'){
     setinviteContentDialog(0);
     if(noredirect == ''){
         Redirectdashboard();
     }
   
  }
//  setadultpostcontentDialog(1)
}



const showShareProfile= async(postid)=>{

 setShowsharepopup(1)

UpdateagreementDialog('invitecontent','',1)


}

const closeShareProfile= async()=>{

setShowsharepopup(0)
window.location.href=frontUrl



}

 useEffect(() => {
      


if(localStorage.getItem('termsReloaded') == null ){
 localStorage.setItem('termsReloaded', 1)

     window.location.reload()
} 


RedirectDashboard_Login()
     getAgreementsupdated()
      // checkSetAgreementsview();
  }, []);


  return (

    <div  > 

     <div>

  <Header pageInfo="Terms Agreement" />

    <div id={(showAcceptpopup == 1 || Showsharepopup  == 1 ) ? 'overlay-hidden':'' } className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section"> 
    
    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xs-9 full-section"> 
 

    <div className="page-content">

    <div className="login-container"> 
        {(parseInt(postContentDialog)  == parseInt(1) ) ? 
        <div className="login-section"> 


         <h1 className="login-title">How do i post my content</h1>
                   <div className="terms-agree-main"> 

          <div className="terms-agree-section"> 
          <h2 className="login-sub-title">Content</h2>
          <p>Create your Youtube channel and post 
          your content in Youtube channel. This 
          will also help you to generate revenue 
          through YouTube. We will help you to 
          get new audience in your Channel 
          through our application. </p>

          <p><span className="terms-video-icon"><i className="amigofa-icon-add-reel ico-term-reel"> </i>   </span>  Click on this icon in your Home page
          and add your YouTube content link. 
          Share your profile link with your friends
          And request them to share with their 
          friends. This way you and your friends 
          will get maximum audience. </p>

</div>

          </div>
 
            

               <button className="terms-agree-button bg-yellow-amigo"  onClick={(e)=> UpdateagreementDialog('postcontent')}   >Continue</button>                                
        </div>: ''
      }


      {(adultpostcontentDialog == 1) ?   
        <div className="login-section"> 


          <h1 className="login-title">Terms and Conditions</h1>
            <div className="terms-agree-main"> 
          <div className="terms-agree-section"> 
          <h2 className="login-sub-title">You will not post any adultery content 

          </h2>
          <p className="highlight">If any unwanted content are posted in 
          your ID, App owner will have right to 
          block your ID and if needed legal action 
          will be taken.

          </p>
</div>
         </div>

          <button className="terms-agree-button bg-yellow-amigo mr-10" onClick={(e)=> UpdateagreementDialog('adultpostcontent')}   >I agree</button>    
          <button className="terms-agree-button bg-yellow-amigo" onClick={(e,dis)=> UpdateagreementDialog('adultpostcontent','disagree')}   >I disagree</button>                                
                              
        </div> : ''
      }

      {(afterpostContentDialog == 1) ? 
      <div className="login-section"> 


          <h1 className="login-title">Terms and Conditions</h1>
            <div className="terms-agree-main"> 
          <div className="terms-agree-section"> 
          <h2 className="login-sub-title">After posting the content

          </h2>
          <p className="highlight">App owner will review and later publish
          your content in the application. If any
          content doesn’t meet our criteria will 
          reject your request

          </p>
</div>
          </div>

          <button className="terms-agree-button bg-yellow-amigo mr-10" onClick={(e)=> UpdateagreementDialog('afterpostcontent')}   >I agree</button>    
          <button className="terms-agree-button bg-yellow-amigo"  onClick={(e,dis)=> UpdateagreementDialog('afterpostcontent','disagree')}   >I disagree</button>                                
                            
      </div>
      : ''
      }
      {(kidsContentDialog == 1) ? 
        <div className="login-section"> 


          <h1 className="login-title">Terms and Conditions</h1>
            <div className="terms-agree-main"> 
          <div className="terms-agree-section"> 
          <h2 className="login-sub-title">This application is for KID’s

          </h2>
          <p className="highlight">We encourage content creater to create
          content that benefits our kid and
          help them to grow their skills.

          </p>

          </div>
          </div>
          <button className="terms-agree-button bg-yellow-amigo mr-10"  onClick={(e)=> UpdateagreementDialog('kidscontent')}  >I agree</button>    
          <button className="terms-agree-button bg-yellow-amigo" onClick={(e,dis)=> UpdateagreementDialog('kidscontent','disagree')}    >I disagree</button>                                
                            
       </div>
       : ''
      }
       {(advContentDialog == 1) ? 
       <div className="login-section"> 


          <h1 className="login-title">Terms and Conditions</h1>
            <div className="terms-agree-main"> 
          <div className="terms-agree-section"> 
          <h2 className="login-sub-title">Advertisement

          </h2>
          <p className="highlight">We encourage Advertiser to Advertise
            their product which are useful to kids or
            to their parents

            These advertisement’s are chargeable 

          </p>

          </div>
          </div>

          <button className="terms-agree-button bg-yellow-amigo mr-10"  onClick={(e)=> UpdateagreementDialog('advcontent')}  >I agree</button>    
          <button className="terms-agree-button bg-yellow-amigo"  onClick={(e,dis)=> UpdateagreementDialog('advcontent','disagree')}   >I disagree</button>                                
                            
       </div>
       : ''
      }
       {(inviteContentDialog == 1) ? 
       <div className="login-section"> 


          <h1 className="login-title">Invite your friends </h1>
  <div className="terms-agree-main"> 
          <div className="terms-agree-section"> 
          <h2 className="login-sub-title">Sharing is Caring


          </h2>
          <p className="highlight">Share this app link with your friends,
so that they can see your post and like
your post. And be famous among your
friends, relatives and neighbours.

More you share more audience you get.
Keep sharing and become famous.
          </p>

          </div>
          </div>

          <button className="terms-agree-button bg-yellow-amigo mr-10"  onClick={showShareProfile}  >Share</button>    
          <button className="terms-agree-button bg-yellow-amigo"  onClick={(e)=> UpdateagreementDialog('invitecontent')}   >Skip</button>                                
                            
       </div>
       : ''
      }


     


    </div>
    </div>


 
    </div>


    </div>

        {(showAcceptpopup == 1) ?  
        <div className="acceptterms-box">
        <div className="acceptterms-bg">

        <p className="acceptTermsError">To create an account you need to 
        accept all the terms and conditions.</p>

        <button className="bg-yellow-amigo mr-10" onClick={ContinueTermsConditions} 
        >Continue</button>    

        <button className="bg-yellow-amigo mr-10" onClick={ExitTermsConditions}   >Exit</button> 
        </div>
        </div> : '' }


        {(Showsharepopup == 1) ?  


        <div className="share-modal-box">

        <div className="share-modal-bg">

        <div className="share-modal-close">

        <button  onClick={closeShareProfile} > X </button>
        </div>

        <Share shareurl={shareurl}  redirect="1"  />





        </div>
        </div> : '' }



    </div>


     </div>
 
  );
};
export default Termsagreementstudent;